import { addDays } from 'date-fns'
import { object, string, number, date, ValidationError } from 'yup'

export const currencyFormatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 0
})

const financialTargetErrorMessage = `Must be between ${currencyFormatter.format(100)} and ${currencyFormatter.format(
  1000000
)}`

const ValidCampaign = object({
  campaignId: string(),
  charityId: string(),
  name: string(),
  description: string(),
  startTime: date().when('createdAt', ([createdAt], schema) => {
    if (createdAt) {
      return schema
    }

    return schema.min(addDays(new Date(), 13))
  }),
  endTime: date().when('startTime', ([startTime], schema) => {
    const minDate = addDays(startTime, 90)
    const maxDate = addDays(startTime, 366)

    return schema
      .min(minDate, `The duration must be between 90 and 365 days.`)
      .max(maxDate, `The duration must be between 90 and 365 days.`)
  }),
  financialTarget: number()
    .typeError(financialTargetErrorMessage)
    .min(100, financialTargetErrorMessage)
    .max(1000000, financialTargetErrorMessage),
  engagementPlan: string(),
  createdAt: string(),
  campaignCode: string(),
  status: string(),
  logoUrl: string(),
  instagramHandle: string().nullable(),
  youtubeHandle: string().nullable(),
  tiktokHandle: string().nullable(),
  facebookHandle: string().nullable(),
  snapchatHandle: string().nullable(),
  linkedinHandle: string().nullable()
})

export function validateCampaign(campaign: Campaign) {
  try {
    ValidCampaign.validateSync(campaign, { abortEarly: false })
    return []
  } catch (error) {
    if (!error) {
      return []
    }

    return (error as ValidationError).inner || []
  }
}
