import React from 'react'
import { InfoIcon } from '../shared'

type Props = {
  content: string
}

export const InfoTooltip = ({ content }: Props) => (
  <div className="relative inline-block w-min [&:last-child]:ml-sm">
    <InfoIcon width="1rem" height="1rem" className="peer" />
    <div className="hidden peer-hover:block transition absolute bg-midnight rounded-half p-md shadow-lg text-white text-sm bottom-[25px] left-[25px] min-w-[200px] z-10">
      {content}
    </div>
  </div>
)
