import React from 'react'

type Props = {
  children: React.ReactNode
  uploadedFileName: string | undefined
  onChange: (file: File) => void
  requestStatus: RequestStatus
  id: string
  acceptedFileTypes?: string
  errorMsg?: string
  required?: boolean
}

export const FileUpload = ({
  children,
  acceptedFileTypes,
  uploadedFileName,
  onChange,
  requestStatus,
  id,
  errorMsg,
  required
}: Props) => (
  <label className="w-max">
    <span>{children}</span>

    <span className="flex items-center justify-center space-x-md">
      {requestStatus === 'loading' && (
        <div>
          <div className="loadingSpinner" />
        </div>
      )}

      <input
        id={id}
        name={id}
        type="file"
        className="border-0"
        accept={acceptedFileTypes || 'application/pdf'}
        onClick={(evt: any) => {
          // need to reset the input value, or else onChange
          // wont fire upon selecting the same image multiple times
          const element = evt.target as HTMLInputElement
          element.value = ''
        }}
        onChange={(evt: any) => evt.target.files[0] && onChange(evt.target.files[0])}
        required={required !== undefined ? required : !uploadedFileName}
      />
    </span>

    {requestStatus === 'failed' || errorMsg ? (
      <span className="text-red ml-sm">{errorMsg ? errorMsg : 'Something went wrong. Please try again.'}</span>
    ) : null}
  </label>
)
