import React from 'react'
import { TermsAndConditionsVICShort } from './TermsAndConditionsVICShort'

export const TermsAndConditionsVICFull = () => {
  return (
    <div className="card m-lg p-lg max-w-screen-md mx-auto">
      <div className="prose">
        <h1>CDS Vic West app donation partner agreement</h1>
        <p>
          TICKING THE &quot;I HAVE READ AND AGREE TO THE TERMS AND CONDITIONS&quot; BOX CONSTITUTES ACCEPTANCE OF ALL
          TERMS AND CONDITIONS OF THIS AGREEMENT BY THE ENTITY APPLYING FOR REGISTRATION AS A CDS VIC WEST APP DONATION
          PARTNER (THE APPLICANT). THE INDIVIDUAL CLICKING THE BUTTON REPRESENTS THAT THEY ARE DULY AUTHORISED TO ENTER
          INTO THIS AGREEMENT ON BEHALF OF THE APPLICANT. ACCEPTANCE OF ALL OF THE TERMS AND CONDITIONS OF THIS
          AGREEMENT IS A PRECONDITION TO PARTICIPATION IN THE CDS VIC WEST APP DONATION PARTNER PROGRAM, AND ACCEPTANCE
          IS LIMITED TO THESE TERMS. ONCE THE APPLICANTCOMPLETES THE APPLICATION PROCESS TO ACTIVATE THEIR DONATION
          PARTNER ID WITH TOMRA CLEANWAY THEY BECOME A CDS VIC WEST APP DONATION PARTNER. THE EFFECTIVE DATE OF THIS
          AGREEMENT WILL BE THE DATE THAT TOMRA CLEANAWAY CONFIRMS IS THE APPLICANT&apos;S DATE OF REGISTRATION AS A CDS
          VIC WEST APP DONATION PARTNER.
        </p>

        <h2>INTRODUCTION</h2>
        <p>
          <ol>
            <li>
              TOMRA Cleanaway (“the Zone Operator”) has been appointed to operate the collection points for the VIC
              Container Deposit Scheme West Zone.
            </li>
            <li>
              TOMRA has been appointed by TOMRA Cleanaway to operate reverse vending machines (RVMs) as collection
              points for the VIC Container Deposit Scheme West Zone and to provide other technology solutions for the
              operation of collection points.
            </li>
            <li>
              The CDS Vic West App is provided by the Zone Operator and gives users the option to donate Refund Amounts
              to charities or other not-for-profit bodies.
            </li>
            <li>
              TOMRA Cleanaway has authorised TOMRA to receive and approve applications from charities and other
              not-for-profit bodies to be registered as CDS Vic West App Donation Partners.
            </li>
            <li>CDS Vic West app Donation Partners will be featured on the CDS Vic West app.</li>
            <li>
              TOMRA will collect and remit to CDS Vic West app Donation Partners all donations of Container Refund
              Amounts made in support of their fundraising campaigns by users via the CDS Vic West app.
            </li>
          </ol>
        </p>

        <h2>TERMS AND CONDITIONS OF AGREEMENT</h2>
        <ol>
          <li>
            <b>DEFINITIONS</b>
            <br />
            Capitalised terms used in this Agreement have the meaning given in the Definitions Schedule below.
          </li>
          <li>
            <b>REGISTRATION AS A CDS VIC WEST APP DONATION PARTNER</b>
            <ol className="list-lowercase">
              <li>
                To be featured on the CDS Vic West app, the Applicant must be registered as a Donation Partner with CDS
                VIC Scheme Coordinator, have a valid Donation Partner ID and meet the eligibility criteria as set in
                Appendix A.
              </li>
              <li>
                The Applicant is required to provide a name and a short description of the objectives of their
                fundraising campaign, a target fundraising amount. and any other details that TOMRA or the Zone Operator
                may require for publication on the CDS Vic West app and in other Scheme publicity materials.
              </li>
              <li>
                Funds raised by the Applicant must only be applied for purposes consistent with the Applicant&apos;s
                charitable or public benevolent purposes and the campaign objectives.
              </li>
              <li>
                The only reporting of amounts donated to the Applicant will be the cumulative balance information
                available to the Applicant on the CDS Vic West app. TOMRA Cleanaway may provide additional reporting in
                its absolute discretion.
              </li>
            </ol>
            <li>
              <b>PAYMENT OF DONATION AMOUNTS</b>
              <ol className="list-lowercase">
                <li>
                  The Applicant must provide TOMRA with particulars of the Applicant&apos;s nominated bank account for
                  receipt of donation amounts, when applying for registration or renewed registration as a CDS Vic West
                  app Donation Partner. The Applicant acknowledges the bank account must be an Australian bank account
                  in the registered name of the Applicant (excluding VIC Government Schools).
                </li>
                <li>
                  TOMRA will pay to the Applicant&apos;s nominated bank account the total of the amounts donated to the
                  Applicant the Applicant each week, within 7 days after the end of that week.
                </li>
                <li>
                  The Applicant will provide TOMRA with a receipt acknowledging each payment made pursuant to this
                  clause for each Financial Year.
                </li>
                <li>
                  If it is found by TOMRA that any donation amount that it previously paid was inaccurately calculated
                  or otherwise overpaid or underpaid by mistake, the parties will make adjustments to payments and
                  receipts as necessary to correct the previous inaccurate or mistaken payment and any receipt based on
                  it.
                </li>
                <li>
                  If TOMRA is unable to make a payment because the Applicant has supplied incorrect or out-of-date
                  particulars of the Applicant&apos;s bank account, TOMRA will make reasonable efforts to contact the
                  Applicant to obtain the correct and up-to-date particulars needed to make the payment to the
                  Applicant. If despite its efforts TOMRA does not receive the correct and up-to-date particulars of the
                  Applicant&apos;s bank account within 6 months of the due date for the payment (or any longer period
                  required by law), TOMRA may in its absolute discretion at any time thereafter donate the payment
                  amount on behalf of the Applicant to any registered charity that TOMRA may choose.
                </li>
              </ol>
            </li>
            <li>
              <b>LICENCE TO DISPLAY DONATION PARTNER NAME AND BRANDING</b>
              <ol className="list-lowercase">
                <li>
                  The Applicant grants TOMRA Cleanaway a non-exclusive, royalty-free licence for the Term to display and
                  publish the Approved Materials (see Appendix B) on the graphical user interface of the CDS Vic West
                  app and in publicity relating to the Applicant&apos;s participation as a donation partner in the
                  Scheme. TOMRA acts as the agent of the Zone Operator, TOMRA Cleanaway, for the purpose of receiving
                  this licence, with the intent that the Zone Operator has the benefit of the licence as if it were a
                  direct party to this Agreement.
                </li>
                <li>
                  The Applicant agrees to supply the Approved Materials in a timely manner when requested by TOMRA or
                  the Zone Operator – TOMRA Cleanaway.
                </li>
                <li>
                  The Applicant further agrees to comply with any reasonable request to supply additional marketing
                  materials along with permission to use such additional marketing materials, Page 4 if requested by
                  TOMRA, the Zone Operator or the CDS VIC Scheme Coordinator for the purpose of advertising and
                  publicising the Applicant&apos;s inclusion as a Scheme donation recipient during the Term.
                </li>
                <li>
                  TOMRA or the Zone Operator may sub-licence to other Scheme stakeholders the right to display and
                  publish Approved Materials and additional materials referred to in this clause, for the purpose of
                  advertising and publicising the Applicant&apos;s participation as a donation partner in the Scheme.
                </li>
              </ol>
            </li>
            <li>
              <b>PROMOTION BY DONATION PARTNER</b>
              <p>The Applicant will use reasonable endeavours to:</p>
              <ol className="list-lowercase">
                <li>
                  raise public awareness of the Applicant&apos;s status as a recipient of donations by users via the CDS
                  Vic West app; and
                </li>
                <li>
                  encourage supporters and members of the public to recycle drink containers through the Refund
                  Collection Points and to use the CDS Vic West app to donate the proceeds to the Applicant.
                </li>
              </ol>
            </li>
          </li>
          <li>
            <b>DONATION RECEIPTS</b>
            <ol className="list-lowercase">
              <li>
                The Applicant authorises TOMRA to make available to users, on the Applicant&apos;s behalf, receipts or
                other evidence of donations of Container Refund Amounts they make to the Applicant.
              </li>
              <li>
                If the Applicant is a deductible gift recipient, TOMRA will make available receipts or other evidence of
                donations in a form that complies with the requirements set out in the Income Tax Assessment Act 1997
                (Cth) for receipts issued by organisations with deductible gift recipient status, including:
                <ol className="list-[upper-roman]">
                  <li>the name of the Applicant;</li>
                  <li>the Australian Business Number (if applicable) of the Applicant;</li>
                  <li>a note stating the amount is a gift;</li>
                  <li>the amount received from the donor; and</li>
                  <li>the date of issue.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>APPLICANT&apos;S ACKNOWLEDGEMENTS</b>
            <p>The Applicant acknowledges that:</p>
            <ol className="list-lowercase">
              <li>
                its eligibility to be nominated by TOMRA Cleanaway as a donation recipient from users under this
                Agreement will continue only for so long as it remains registered by the Zone operator as a CDS Vic West
                app Donation Partner;
              </li>
              <li>
                its nomination by TOMRA Cleanaway as a donation recipient from users under this Agreement will operate
                for the duration of each Featured Campaign, which will run for Page 5 a minimum of 12 months. Each
                featured campaign can be renewed and extended by TOMRA in their sole discretion;
              </li>
              <li>
                its nomination by TOMRA Cleanaway as a donation recipient from users under this Agreement will not be an
                exclusive nomination (as CDS Vic West app users will be given a choice to direct their donations to
                other recipients);
              </li>
              <li>
                TOMRA gives no undertakings to the Applicant about the position of the Applicant on the graphical user
                interface of the CDS Vic West app viz a viz other donation recipients that appear there at the same
                time.
              </li>
            </ol>
          </li>
          <li>
            <b>DELAYS AND PERFORMANCE FAILURES</b>
            <ul className="list-lowercase">
              <li>
                TOMRA or Zone Operator, TOMRA Cleanaway will use all reasonable endeavours within its control but does
                not guarantee to ensure that the either refund collection points or the CDS Vic West app are installed
                and operating in the manner contemplated by this Agreement, either on the Start Date or at all times
                during the Term.
              </li>
              <li>
                The Applicant acknowledges that the installation, commissioning, and operation of the refund collection
                points, the CDS Vic West app and related information and payment systems may be subject to technical and
                logistical problems.
              </li>
              <li>
                If TOMRA or Zone Operator, TOMRA Cleanaway, is unable to perform or is delayed in performing any of its
                obligations under this Agreement because of an equipment or systems failure, or any event outside of its
                reasonable control, the affected obligation will be suspended for so long as the failure or event
                continues.
              </li>
              <li>
                To the extent permitted by law, TOMRA&apos;s liability to the Applicant under or in respect of this
                Agreement whether in contract, tort (including negligence), statute or any other cause of action is
                limited to direct loss in the amount of any donation sums that TOMRA has failed to pay to or as directed
                by the Applicant in accordance with this Agreement.
              </li>
            </ul>
          </li>
          <li>
            <b>DURATION OF AGREEMENT</b>
            <p>
              This Agreement will continue to operate for so long as the Applicant remains registered as a CDS Vic West
              app Donation Partner, subject to early termination under clause 13.
            </p>
          </li>
          <li>
            <b>REGISTRATION PERIOD</b>
            <p>
              TOMRA or Zone Operator, TOMRA Cleanaway may from time to time require the Applicant to provide updated
              registration details (including bank account details, contact details) to ensure they remain current.
            </p>
          </li>
          <li>
            <b>TERMINATION</b>
            <ol className="list-lowercase">
              <li>
                TOMRA or Zone Operator, TOMRA Cleanaway may terminate this Agreement and the registration of the
                Applicant as a CDS Vic West app Donation Partner at any time by notice to the Applicant (and with or
                without any period of prior notice), if:
                <ol className="list-[upper-roman]">
                  <li>
                    the Applicant no longer meets the Eligibility Criteria set by CDS VIC or that a Material Adverse
                    Event has occurred in relation to the Applicant;
                  </li>
                  <li>
                    TOMRA Cleanaway ceases for any reason to be an authorised operator of refund collection points under
                    the Scheme or ceases to be authorised to offer the CDS Vic West app as a method of donating
                    Container Refund Amounts; or
                  </li>
                  <li>
                    the Zone Operator directs TOMRA or the State directs the Zone Operator to terminate the
                    participation of the Applicant as a recipient of Scheme-related donations.
                  </li>
                </ol>
              </li>
              <li>
                The Applicant may terminate this Agreement and the registration of the Applicant as a CDS Vic West app
                Donation Partner at any time by notice to TOMRA or Zone Operator, TOMRA Cleanaway (and with or without
                any period of prior notice), if:
                <ol className="list-[upper-roman]">
                  <li>
                    the Applicant reasonably forms the view that a Material Adverse Event has occurred in relation to
                    TOMRA or Zone Operator, TOMRA Cleanaway.
                  </li>
                  <li>
                    TOMRA or Zone Operator, TOMRA Cleanaway transfers or proposes to transfer its rights and obligations
                    to a third party in accordance with clause 15 and the Applicant does not wish to partner with the
                    third party.
                  </li>
                </ol>
              </li>
              <li>
                Any termination under this clause will not affect any donation entitlements of the Applicant that have
                accrued up to the termination date.
              </li>
            </ol>
          </li>
          <li>
            <b>DATA PROTECTION</b>
            <ol className="list-lowercase">
              <li>
                TOMRA and Zone Operator, TOMRA Cleanaway will comply with applicable privacy laws and its published
                privacy policy from time to time, in relation to collection, handling, storage and use of the personal
                information of users of the CDS Vic West app. The Applicant should refer to the Zone Operator&apos;s
                published privacy policy for information relating to the collection, handling, storage and use of the
                personal information of users of the CDS Vic West app by the Zone Operator
              </li>
              <li>
                TOMRA and Zone Operator, TOMRA Cleanaway will ensure that all data received and retained in connection
                with the Applicant or the CDS Vic West app is hosted within Australia.
              </li>
              <li>
                TOMRA may share with the Zone Operator, and CDS VIC scheme coordinator any data received by TOMRA
                relating to the Applicant, including donations made to the Applicant.
              </li>
            </ol>
          </li>
          <li>
            <b>ASSIGNMENT</b>
            <p>
              This Agreement is personal to the Applicant, and it may not transfer any of the rights and benefits
              conferred on it under this Agreement. TOMRA or Zone Operator, TOMRA Cleanaway may transfer rights and
              obligations under this Agreement by assignment or novation to the State, the Zone Operator or any other
              person who may from time to time be appointed to operate the RVMs as Scheme refund collection points.
            </p>
          </li>
          <li>
            <b>GENERAL PROVISIONS</b>
            <ol className="nested-list-counters">
              <li className="list-none nested-list-counters-li">
                <b>GST</b>
                <ol className="list-lowercase">
                  <li>
                    If GST is imposed or levied in respect of any supply by a party under or in accordance with this
                    Agreement the party making the supply may recover the GST Amount from the party receiving the supply
                    in addition to the Consideration. The party making the supply will provide such invoices to the
                    party receiving the supply as are required pursuant to GST Legislation, provided that this does not
                    apply where TOMRA is the party receiving the supply and has elected to issue a RCTI in relation to
                    that supply.
                  </li>
                  <li>
                    TOMRA may issue a RCTI to the Applicant in respect of any supply made to TOMRA by the Applicant in
                    connection with this Agreement. The Applicant must not issue a tax invoice in respect of any supply
                    for which TOMRA has elected to issue a RCTI.
                  </li>
                  <li>
                    Each party will notify the other if it ceases to be registered for GST. TOMRA will notify the
                    Applicant if it ceases to satisfy the requirements of any determination made under GST Legislation
                    with respect to the issue of RCTIs.
                  </li>
                  <li>
                    TOMRA may at any time withdraw an election to issue RCTIs by notice to the Applicant, in respect of
                    specified supplies, in which case the Applicant will be required to issue tax invoices to TOMRA in
                    respect of those supplies.
                  </li>
                </ol>
              </li>
              <li className="list-none nested-list-counters-li">
                <b>Whole agreement</b>
                <p>This Agreement comprises the whole of the agreement between the parties.</p>
              </li>
              <li className="list-none nested-list-counters-li">
                <b>Notices</b>
                <ol className="list-lowercase">
                  <li>
                    Any notice to be given under this Agreement by one of the parties to the other must be in writing
                    and is given for all purposes by e-mail.
                  </li>
                  <li>
                    Any notice to the Applicant may be sent to the e-mail address notified by the Applicant in its
                    registration details most recently provided to TOMRA at the time of the notice. Notices to TOMRA may
                    be sent to charities.vic@tomra.com.
                  </li>
                  <li>
                    Any e-mailed notice given in accordance with this Agreement will be deemed to have been duly served
                    when transmitted by the sender unless the sender receives a message from its or the recipient&apos;s
                    internet service provider or mail server indicating that it has not been successfully transmitted.
                  </li>
                  <li>
                    A party may at any time change its e-mail address for the service of notices under this Agreement by
                    giving written notice to the other party.
                  </li>
                </ol>
              </li>
              <li className="list-none nested-list-counters-li">
                <b>Relationship</b>
                <p>
                  The parties are independent contractors. This Agreement is not intended and will not be taken to
                  constitute any partnership, agency, trust or joint venture relationship between the parties.
                </p>
              </li>
              <li className="list-none nested-list-counters-li">
                <b>Governing law</b>
                <p>This Agreement is governed by the law in force in the State of Victoria.</p>
              </li>
              <li className="list-none nested-list-counters-li">
                <b>Giving effect to this Agreement</b>
                <p>
                  Each party must do anything (including execute any document) that the other party may reasonably
                  require to give full effect to this Agreement.
                </p>
              </li>
            </ol>
          </li>
        </ol>

        <h2>
          DEFINITIONS SCHEDULE <br />
          (Definitions and interpretation)
        </h2>
        <h3>Definitions</h3>
        <p>In this Agreement, unless the context otherwise requires:</p>
        <p>
          <b>Approved Materials</b> means the logos, text and other materials set out or described in Appendix B.
        </p>
        <p>
          <b>Business Day</b> means a day other than a Saturday, Sunday or public or bank holiday in Victoria.
        </p>
        <p>
          <b>Consideration</b> means any amount or consideration payable or to be provided pursuant to any provision of
          this Agreement.
        </p>
        <p>
          <b>Container Refund Amount</b> means the refund amount payable for a container deposited at a refund
          collection point, as prescribed under the regulations governing the Scheme from time to time.
        </p>
        <p>
          <b>GST</b> means any form of goods and services tax or similar value added tax.
        </p>
        <p>
          <b>GST Amount</b> means the Consideration (after deducting the GST Exempt Component) multiplied by the GST
          Rate.
        </p>
        <p>
          <b>GST Exempt Component</b> means any part of the Consideration which solely relates to a supply that is free
          or exempt from the imposition of GST.
        </p>
        <p>
          <b>GST Legislation</b> means A New Tax System (Goods and Services Tax) Act 1999 (Cth) and any other
          legislation or regulation which imposes, levies, implements or varies a GST or any applicable rulings issued
          by the Commissioner of Taxation.
        </p>
        <p>
          <b>GST Rate</b> means the rate at which GST Legislation from time to time imposes or levies GST on the
          relevant supply.
        </p>
        <p>
          <b>Material Adverse Event</b> means an event, matter, circumstance, public allegation or controversy relating
          to a party occurs or becomes known to the other party and, in the other party&apos;s reasonable opinion, the
          event, matter, circumstance, public allegation or controversy has resulted in or could reasonably be expected
          to result in a material adverse effect on the reputation or public perceptions of either party or the Scheme.
        </p>
        <p>
          <b>Zone operator</b> means TOMRA Cleanaway Pty Limited (ABN 43 620 427 116) or any successor appointed as the
          &apos;Zone Operator&apos; for the Scheme.
        </p>
        <p>
          <b>RCTI</b> means a recipient created tax invoice as defined in the GST Legislation.
        </p>
        <p>
          <b>RVM</b> has the meaning given in paragraph (A) of the Introduction to this Agreement.
        </p>
        <p>
          <b>Eligibility Criteria</b> means the criteria set out or referred to in Appendix A.
        </p>
        <p>
          <b>CDS Vic West app</b> means the application for mobile devices made available by the Zone Operator to users
          of container refund collection points under the Scheme, including any modification or new version of that
          application that the Zone Operator may issue from time to time.
        </p>
        <p>
          <b>CDS Vic West app Donation Partner</b> means an entity that is registered by the Zone Operator (or by TOMRA
          if authorised on the Zone Operator&apos;s behalf) as eligible and permitted to receive donations of Container
          Refund Amounts for its Featured Campaigns, by direction from users made via the CDS Vic West app.
        </p>
        <p>
          <b>Scheme</b> means the Container Deposit Scheme established under Part 5 of the Waste Avoidance and Resource
          Recovery Act 2001 (VIC).
        </p>
        <p>
          <b>State</b> means the Crown in right of the State of Victoria
        </p>
        <p>
          <b>Supply</b> includes supply as defined under GST Legislation.
        </p>
        <p>
          <b>Term</b> means the period of operation of this Agreement, as stated in clause 11.
        </p>
        <p>
          <b>TOMRA</b> means TOMRA Collection Pty Ltd (ABN 90 111 224 018) of Level 5, Building A/207 Pacific Hwy, St
          Leonards NSW 2065
        </p>

        <h3>Interpretation</h3>
        <p>
          The following rules of interpretation apply to this Agreement unless the context indicates a contrary
          intention:
        </p>
        <ol className="list-lowercase">
          <li>
            a reference to any party includes that party&apos;s administrators, successors and permitted assigns,
            including any person taking by way of novation and, in the case of a trustee, includes any substituted or
            additional trustee;
          </li>
          <li>
            a reference to any document (including this Agreement) is to that document as varied, novated, ratified or
            replaced from time to time;
          </li>
          <li>
            a reference to any statute or to any statutory provision includes any statutory modification or re-enactment
            of it or any statutory provision substituted for it, and all ordinances, by-laws, regulations, rules and
            statutory instruments (however described) issued under it;
          </li>
          <li>
            words importing the singular include the plural (and vice versa), and words indicating a gender include
            every other gender;
          </li>
          <li>
            references to parties, clauses, schedules, exhibits or annexures are references to parties, clauses,
            schedules, exhibits and annexures to or of this Agreement, and a reference to this Agreement includes any
            schedule, appendix, exhibit or annexure to this Agreement;
          </li>
          <li>
            where a word or phrase is given a defined meaning, any other part of speech or grammatical form of that word
            or phrase has a corresponding meaning;
          </li>
          <li>
            the word <b>month</b> means a calendar month;
          </li>
          <li>
            the word <b>including</b> or words of like meaning are not words of limitation; and
          </li>
          <li>
            a reference to <b>$</b> or <b>dollar</b> is to Australian currency.
          </li>
        </ol>

        <h2>
          APPENDIX A<br />
          (Eligibility Criteria for CDS Vic West app Donation Partners)
        </h2>
        <TermsAndConditionsVICShort />

        <h2>
          APPENDIX B<br />
          (Approved Materials)
        </h2>
        <table className="table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Context / Use</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&quot;Display Name&quot;: brand name or commonly used name (if different from legal name)</td>
              <td>
                To appear on the &apos;Charities&apos; pages on the CDS Vic West app.
                <br />
                <br />
                TOMRA and the Zone Operator reserve the right to not use any name that is not the registered name of the
                organisation or a business name or trademark owned by the organisation.
              </td>
            </tr>
            <tr>
              <td>Text Description of Donation Partner</td>
              <td>
                A text description of the CDS Vic West app Donation Partner’s organisation of up to 500 characters that
                may be used on websites, press releases, fact sheets, the CDS Vic West app and other publicly available
                information sources published by TOMRA or other stakeholders associated with the Scheme including but
                not limited to the State, the Zone Operator and the CDS Vic website.
              </td>
            </tr>
            <tr>
              <td>Key Graphics Files (not compulsory)</td>
              <td>
                Graphics files that may include the CDS Vic West app Donation Partner’s logo and other messaging and
                which can be used for the same purposes as described in the “Text Description” immediately above. (Not
                compulsory or required if your organisation does not have them or cannot easily make them available).
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          TOMRA and the Zone Operator retain the right to approve or reject any names or images submitted as Approved
          Materials.
        </p>
      </div>
    </div>
  )
}
