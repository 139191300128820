import logoPlaceholder from './images/logo-placeholder.svg'
import { v4 as uuidv4 } from 'uuid'
import { THEME } from './lib'
import { addDays, subYears } from 'date-fns'

const organizationInit: Organization = {
  name: '',
  websiteUrl: '',
  organizationAbn: '',
  operatingState: THEME.stateShort,
  establishedDate: addDays(subYears(new Date(), 2), 1).toISOString(),
  legalStructure: '',
  legalStructureDetails: '',
  geoFootprint: '',
  incorporationNumber: '',
  displayName: '',
  localGroupName: '',
  streetAddress: '',
  postCode: '',
  suburb: '',
  benefit: '',
  description: '',
  registeredState: ''
}

const bankingDataInit: BankingData = {
  accountName: '',
  accountBsb: '',
  accountNumber: '',
  dgrStatus: ''
}

const primaryContactInit: PrimaryContact = {
  firstName: '',
  lastName: '',
  position: '',
  email: '',
  phone: ''
}

const secondaryContactInit: SecondaryContact = {
  firstName: '',
  lastName: '',
  position: '',
  email: '',
  phone: ''
}

const uiFieldsInit: CharityUiFields = {
  abnFileName: '',
  incorporationFileName: '',
  parentOrganizationLetterFileName: '',
  organizationLogoFileName: '',
  proofOfBankDetailsFileName: '',
  abnBelongsToParent: false,
  hasAcceptedTos: false,
  operatesInState: false
}

const charityInit: Charity = {
  id: uuidv4(),
  organization: organizationInit,
  bankingData: bankingDataInit,
  primaryContact: primaryContactInit,
  secondaryContact: secondaryContactInit,
  logoUrl: logoPlaceholder
}

export { charityInit, uiFieldsInit }
