import React, { useEffect } from 'react'
import { createBrowserRouter, Outlet, RouterProvider, useNavigate } from 'react-router-dom'
import { CampaignForm } from './components/campaigns/CampaignForm'
import { LoginPage } from './pages/login/LoginPage'
import { auth } from './firebase'
import { ProfilePage } from './pages/profile/ProfilePage'
import { AppLayout } from './AppLayout'
import { TermsAndConditionsPage } from './pages/terms-and-conditions/TermsAndConditionsPage'
import { DashboardPage } from './pages/dashboard/DashboardPage'
import { CreateCharityPage } from './pages/charity/CreateCharityPage'
import { UpdateCharityPage } from './pages/charity/UpdateCharityPage'
import { initDatadog } from '@tomra/datadog-browser-logging'
import { DATADOG_CONFIG, ENVIRONMENT, STAGE, VERSION } from './lib'
import { GeneralErrorBoundary, onGeneralErrorBoundary, RouterErrorBoundary } from './components/ErrorPage'
import { ErrorBoundary } from 'react-error-boundary'
import { initAmplitude } from './lib/amplitudeUtils'

const ProtectedLayout = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (auth.currentUser === null) {
      return navigate('/login')
    } else if (!auth.currentUser.displayName) {
      return navigate('/profile')
    }
  }, [navigate])

  return auth.currentUser === null ? (
    <div className="centerAbsolute">
      <div className="loadingSpinner" />
    </div>
  ) : (
    <Outlet />
  )
}

const PageNotFound = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/')
  }, [])

  return <div />
}

const authenticatedRoutes = [
  {
    id: 'root',
    element: <AppLayout />,
    errorElement: <RouterErrorBoundary />,
    children: [
      {
        element: <ProtectedLayout />,
        children: [
          {
            path: '',
            element: <DashboardPage />
          },
          {
            path: 'profile',
            element: <ProfilePage />
          },
          {
            path: 'charity',
            element: <CreateCharityPage />
          },
          {
            path: 'charity/:charityId',
            element: <UpdateCharityPage />
          },
          {
            path: 'charity/:charityId/campaign',
            element: <CampaignForm />
          },
          {
            path: 'charity/:charityId/campaign/:campaignId',
            element: <CampaignForm />
          }
        ]
      },
      {
        path: 'login',
        element: <LoginPage />
      },
      {
        path: '/tos',
        element: <TermsAndConditionsPage />
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  }
]

export const App = () => {
  useEffect(() => {
    initAmplitude()
    initDatadog({ ...DATADOG_CONFIG, version: VERSION, env: `${ENVIRONMENT}.${STAGE}` })
  }, [])
  return (
    <ErrorBoundary fallbackRender={GeneralErrorBoundary} onError={onGeneralErrorBoundary}>
      <RouterProvider router={createBrowserRouter(authenticatedRoutes)} />
    </ErrorBoundary>
  )
}
