import React from 'react'
import { InfoTooltip } from './InfoTooltip'

type Props = {
  htmlFor?: string
  tooltipContent?: string
  children: React.ReactNode
}

export const Label = ({ htmlFor, tooltipContent, children }: Props) => (
  <div className="flex">
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label htmlFor={htmlFor} className="label">
      <small>
        <b>{children}</b>
      </small>
    </label>
    {tooltipContent && <InfoTooltip content={tooltipContent} />}
  </div>
)
