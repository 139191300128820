import React, { FunctionComponent, useState } from 'react'
import { ImageUpload } from './ImageUpload'
import { Label } from './Label'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import type { StorageReference } from 'firebase/storage'
import { storage } from '../../firebase'
import { logError } from '@tomra/datadog-browser-logging'

type Props = {
  charityId: string
  organizationLogoRequestStatus: RequestStatus
  setOrganizationLogoRequestStatus: React.Dispatch<React.SetStateAction<RequestStatus>>
  uiFields: CharityUiFields
  onImageUploaded: (uiFields: Partial<CharityUiFields>, logoUrl: string) => void
}

const delay = (delayMs: number) => new Promise(resolve => setTimeout(resolve, delayMs))

// We don't need the token for public images, so just remove it to prevent storing tokens in the database
const removeTokenFromUrl = (url: string) => url.split('&')?.[0]

const retryGetImageUrl = (retriesRemaining: number, thumbnailRef: StorageReference): Promise<string> => {
  if (retriesRemaining < 0) {
    return Promise.reject('No more retries')
  }

  return getDownloadURL(thumbnailRef)
    .then(removeTokenFromUrl)
    .catch(error => {
      if (error.code === 'storage/object-not-found') {
        return delay(1000).then(() => retryGetImageUrl(retriesRemaining - 1, thumbnailRef))
      }

      return Promise.reject(error)
    })
}

const uploadImage = (
  organizationLogoFileName: string,
  organizationLogoBlob: Blob,
  updatePercentageCb: (progress: number) => void
): Promise<string> => {
  const storageRef = ref(storage, `uploads/${organizationLogoFileName}`)
  const uploadTask = uploadBytesResumable(storageRef, organizationLogoBlob)

  const thumbnailRef = ref(storage, `uploads/public/${organizationLogoFileName}_400x400`)

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        updatePercentageCb(progress)
      },
      reject,
      () =>
        retryGetImageUrl(10, thumbnailRef)
          .then(resolve)
          .catch(error => logError(new Error('Unable to fetch image URL', error)))
    )
  })
}

export const FirebaseImageUpload: FunctionComponent<Props> = ({
  charityId,
  organizationLogoRequestStatus,
  setOrganizationLogoRequestStatus,
  uiFields,
  onImageUploaded
}) => {
  const [progressPercent, setProgressPercent] = useState(0)

  const onOrganizationLogoUpload = async (organizationLogoBlob: Blob, originalName: string) => {
    setOrganizationLogoRequestStatus('loading')

    try {
      const organizationLogoFileName = `${charityId}-${Date.now()}`
      const organizationLogoUrl = await uploadImage(organizationLogoFileName, organizationLogoBlob, setProgressPercent)

      onImageUploaded(
        {
          organizationLogoFileName: originalName
        },
        organizationLogoUrl
      )
      setOrganizationLogoRequestStatus('initial')
    } catch (error) {
      setOrganizationLogoRequestStatus('failed')
      logError(new Error('Failed to upload OrganizationLogo file'), error as Error)
    }
  }

  return (
    <>
      <ImageUpload
        id="organizationLogo"
        uploadedFileName={uiFields.organizationLogoFileName}
        onChange={onOrganizationLogoUpload}
        requestStatus={organizationLogoRequestStatus}
        required={false}
      >
        <Label tooltipContent="Logo must have a width AND height of at least 500 pixels. Format must be either JPG or PNG, and file size cannot exceed 2 megabytes.">
          Organisation logo
        </Label>
      </ImageUpload>

      {organizationLogoRequestStatus === 'loading' && (
        <>
          <progress
            className="w-full mt-sm [&::-webkit-progress-bar]:bg-fog [&::-webkit-progress-bar]:rounded-half [&::-webkit-progress-value]:bg-green-light [&::-webkit-progress-value]:rounded-half [&::-moz-progress-bar]:bg-green-light [&::-moz-progress-bar]:rounded-half"
            aria-label="Uploading progress"
            value={progressPercent}
            max="100"
          />
          <div className="text-right">{progressPercent}%</div>
        </>
      )}
    </>
  )
}
