import React, { FunctionComponent } from 'react'
import { Accordion } from '../../shared'
import { TermsAndConditionsNSWShort } from '../../../pages/terms-and-conditions/schemes/TermsAndConditionsNSWShort'

type Props = {
  uiFields: CharityUiFields
  onUiFieldsChange: (uiFields: Partial<CharityUiFields>) => void
}

export const AcceptNSWTos: FunctionComponent<Props> = ({ uiFields, onUiFieldsChange }) => {
  return (
    <Accordion title="Terms and conditions">
      <div className="prose">
        <TermsAndConditionsNSWShort />
      </div>

      <div>
        <a href="/tos" target="_blank" className="font-bold underline block mb-lg">
          Click here to read the full terms and conditions
        </a>

        <div className="flex items-center">
          <input
            id="tos"
            type="checkbox"
            className="checkbox mr-sm"
            checked={uiFields.hasAcceptedTos}
            onChange={evt => onUiFieldsChange({ hasAcceptedTos: evt.target.checked })}
            required
          />
          <label className="flex-auto select-none" htmlFor="tos">
            I have read and agree to the terms and conditions
          </label>
        </div>
      </div>
    </Accordion>
  )
}
