import React, { FunctionComponent, useEffect } from 'react'
import anime from 'animejs'
import { AnimatedClouds } from './AnimatedClouds'
import { AnimatedPaperPlane } from './AnimatedPaperPlane'
import { THEME } from '../../../lib'
const { supportEmail } = THEME

type Props = {
  email: string
  status: LoginRequestStatus
  goBack: () => void
}

export const MagicLinkSent: FunctionComponent<Props> = ({ email, status, goBack }) => {
  useEffect(() => {
    anime({
      targets: '#background',
      easing: 'linear',
      duration: 250,
      backgroundColor: ['rgba(31,192,218,0.02)']
    })
  }, [])

  return (
    <div id="background" className="fixed left-0 top-lg w-full h-full pt-[2rem] z-[1] animate-fadeIn">
      <AnimatedClouds />

      {status === 'sending' && (
        <div className="w-full mt-[30%] text-center">
          <div className="loadingSpinner" />
          <h1 className="text-lg text-midnight mt-md">Enjoy the sky while you wait...</h1>
        </div>
      )}

      {status === 'link_sent' && (
        <>
          <AnimatedPaperPlane />

          <div className="mx-auto max-w-screen-sm flex flex-col items-center prose animate-fadeIn">
            <h1>We&apos;ve sent you a link</h1>
            <div>
              Please check your email: <span className="font-bold">{email}</span>
            </div>

            <h2>Didn&apos;t receive a link?</h2>
            <ul className="list-disc">
              <li>Check your spam folder</li>
              <li>Check the spelling of your email address</li>
              <li>
                If nothing works, contact support at{' '}
                <a className="font-bold" href={`mailto:${supportEmail}`}>
                  {supportEmail}
                </a>
              </li>
            </ul>

            <button className="btn" onClick={goBack}>
              Go back
            </button>
          </div>
        </>
      )}
    </div>
  )
}
