import React, { useState } from 'react'
import { Label, Accordion } from '../'
import { benefitToReadableString } from './enumsToReadableStrings'
import logoAppPreview from '../../../images/logoAppPreview.png'
import { FirebaseImageUpload } from '../FirebaseImageUpload'
import { useLocation } from 'react-router-dom'
import { THEME } from '../../../lib'

type Props = {
  charityId: string
  uiFields: CharityUiFields
  onUiFieldsChange: (uiFields: Partial<CharityUiFields>) => void
  organization: Organization
  onOrganizationFieldsChange: (organization: Partial<Organization>) => void
  logoUrl: string
  setLogoUrl: (logoUrl: string) => void
}

const { displayName } = THEME

export const Description = ({
  charityId,
  uiFields,
  onUiFieldsChange,
  organization,
  onOrganizationFieldsChange,
  logoUrl,
  setLogoUrl
}: Props) => {
  const [logoPreviewHasLoaded, setLogoPreviewHasLoaded] = useState(false)
  const [organizationLogoRequestStatus, setOrganizationLogoRequestStatus] = useState<RequestStatus>('initial')
  const { pathname } = useLocation()

  return (
    <Accordion title="About your organisation">
      <div className="md:flex md:items-start md:space-x-lg">
        <div className="flex flex-col flex-1 space-y-lg">
          <div>
            <Label
              htmlFor="displayName"
              tooltipContent={`The actual name as it will be shown in ${displayName}. Note: TOMRA reserves the right to not use any name that is not the registered name of the organisation or a business name or trademark owned by the organisation.`}
            >
              Display name
            </Label>

            <input
              type="text"
              id="displayName"
              value={organization.displayName}
              onChange={evt => onOrganizationFieldsChange({ displayName: evt.target.value })}
              required
            />
          </div>

          <div>
            <Label htmlFor="websiteUrl">Website url</Label>

            <input
              type="text"
              id="websiteUrl"
              value={organization.websiteUrl}
              pattern=".*\..*"
              onChange={evt => onOrganizationFieldsChange({ websiteUrl: evt.target.value })}
            />
          </div>

          <div>
            <Label htmlFor="benefit">Main community benefit</Label>

            <select
              id="benefit"
              className="select block"
              value={organization.benefit}
              onChange={(evt: any) => onOrganizationFieldsChange({ benefit: evt.target.value })}
              required
            >
              <option value="">Select one &or;</option>

              {Object.keys(benefitToReadableString).map(key => (
                <option value={key} key={key}>
                  {
                    // @ts-ignore
                    benefitToReadableString[key]
                  }
                </option>
              ))}
            </select>
          </div>

          <div>
            <Label htmlFor="description">Description of your organisation</Label>

            <textarea
              id="description"
              className="textarea"
              placeholder="Provide a brief description about your organization, who you are, what you bring to the community, why these funds are important, how they will be spent, who will be benefiting from these donations"
              maxLength={500}
              value={organization.description}
              onChange={(evt: any) => onOrganizationFieldsChange({ description: evt.target.value })}
              required
            />
            <p>{organization.description.length}/500 characters</p>
          </div>
        </div>

        <div className="mt-md md:mt-0">
          <div className="relative w-18 -mt-md">
            <img src={logoAppPreview} alt="logo preview in app" className="w-18 min-w-18" />

            <div className="w-6 h-6 rounded border-2 centerAbsolute overflow-hidden">
              {!logoPreviewHasLoaded && (
                <div className="centerAbsolute">
                  <div className="loadingSpinner" />
                </div>
              )}
              <img
                onLoad={() => setLogoPreviewHasLoaded(true)}
                src={logoUrl}
                style={{ objectFit: 'cover', opacity: logoPreviewHasLoaded ? 1 : 0 }}
                alt="logo preview"
              />
            </div>
          </div>

          {pathname === '/charity' && (
            <div>
              <FirebaseImageUpload
                charityId={charityId}
                organizationLogoRequestStatus={organizationLogoRequestStatus}
                setOrganizationLogoRequestStatus={setOrganizationLogoRequestStatus}
                uiFields={uiFields}
                onImageUploaded={(uiFields, logoUrl) => {
                  onUiFieldsChange(uiFields)
                  setLogoUrl(logoUrl)
                  setLogoPreviewHasLoaded(false)
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Accordion>
  )
}
