import React from 'react'
import { THEME } from '../../../lib'
import { AcceptNSWTos } from '../terms-and-conditions/AcceptNSWTos'
import { AcceptVICTos } from '../terms-and-conditions/AcceptVICTos'

type Props = {
  uiFields: CharityUiFields
  onUiFieldsChange: (uiFields: Partial<CharityUiFields>) => void
}

const { stateShort } = THEME

export const TermsAndConditions = ({ uiFields, onUiFieldsChange }: Props) => {
  return stateShort === 'NSW' ? (
    <AcceptNSWTos uiFields={uiFields} onUiFieldsChange={onUiFieldsChange} />
  ) : (
    <AcceptVICTos uiFields={uiFields} onUiFieldsChange={onUiFieldsChange} />
  )
}
