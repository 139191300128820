import React, { FormEvent, FunctionComponent, useState } from 'react'
import { getDonationPartnerDetails } from '../../../services'
import { THEME } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { AlertIcon } from '../../../components/shared'
import { donationPartnerIdIsValid } from './donationPartner'
const { supportEmail } = THEME

type Props = {
  onConfirmDetails: (details: DonationPartnerDetails) => void
}

export const DonationPartnerForm: FunctionComponent<Props> = ({ onConfirmDetails }) => {
  const [status, setStatus] = useState<RequestStatus>('initial')
  const [donationPartnerId, setDonationPartnerId] = useState('')
  const [donationPartnerDetails, setDonationPartnerDetails] = useState<DonationPartnerDetails | null>()
  const [donationPartnerIdValid, setDonationPartnerIdValid] = useState(false)

  const fetchDonationPartnerDetails = async (e: FormEvent) => {
    e.preventDefault()

    try {
      setStatus('loading')
      const details = await getDonationPartnerDetails(donationPartnerId)
      setDonationPartnerDetails(details)
      setStatus('initial')
    } catch (error: any) {
      if (error.status === 412) {
        setStatus('preConditionFailed')
      } else if (error.status === 404) {
        setStatus('notFound')
      } else if (error.status === 409) {
        setStatus('conflict')
      } else {
        logError(new Error('Unable to fetch donation partner details'), error)
        setStatus('failed')
      }
    }
  }

  const reset = () => {
    setDonationPartnerDetails(null)
  }

  const donationPartnerIdIsNotValid = donationPartnerId.length === 11 && !donationPartnerIdValid

  return (
    <div className="max-w-screen-sm mx-auto my-lg card p-xl">
      {donationPartnerDetails ? (
        <div className="flex flex-col space-y-lg">
          <h1 className="text-xl">Please verify</h1>

          <div>
            <div className="label">Donation Partner ID</div>
            <div>{donationPartnerDetails.donationPartnerId}</div>
          </div>
          <div>
            <div className="label">Legal Name</div>
            <div>{donationPartnerDetails.legalName}</div>
          </div>
          <div>
            <div className="label">Business Registration Number</div>
            <div>{donationPartnerDetails.businessRegistrationNumber}</div>
          </div>

          <div className="flex space-x-md justify-end">
            <button type="button" className="btn" onClick={reset}>
              No, go back
            </button>
            <button
              type="button"
              className="btn btn-primary-dark"
              onClick={() => onConfirmDetails(donationPartnerDetails)}
            >
              Yes, this is correct
            </button>
          </div>
        </div>
      ) : (
        <>
          <h1 className="text-xl mb-md">Please enter your Donation Partner ID</h1>

          <form className="flex flex-col space-y-md items-start" onSubmit={fetchDonationPartnerDetails}>
            <div className="w-full">
              <div className="flex justify-between items-end">
                <label htmlFor="donationPartnerId" className="label">
                  Donation Partner ID
                </label>
                <div className="text-right text-sm">{donationPartnerId.length} / 11</div>
              </div>

              <input
                id="donationPartnerId"
                type="text"
                value={donationPartnerId}
                maxLength={11}
                onChange={({ target }) => {
                  const value = target.value
                  setDonationPartnerId(value)
                  setDonationPartnerIdValid(donationPartnerIdIsValid(value))
                }}
                required
              />
            </div>

            {status === 'loading' ? (
              <div className="loadingSpinner" />
            ) : (
              <button
                type="submit"
                className="btn btn-primary-dark"
                aria-disabled={!donationPartnerId || !donationPartnerIdValid}
              >
                Get details
              </button>
            )}

            {donationPartnerIdIsNotValid && (
              <div className="font-bold text-red-dark flex items-start space-x-sm">
                The Donation Partner ID is not valid. Are you sure you entered the correct ID?
              </div>
            )}

            {status === 'notFound' && (
              <div className="font-bold text-red-dark flex items-start space-x-sm">
                <AlertIcon width="1.5rem" height="1.5rem" />
                <span>
                  We couldn&apos;t find any donation partners with this id. Are you sure you entered the correct number?
                </span>
              </div>
            )}

            {status === 'conflict' && (
              <div className="font-bold text-red-dark flex items-start space-x-sm">
                <AlertIcon width="1.5rem" height="1.5rem" />
                <span>
                  The Donation Partner you entered is already in use with another e-mail. Please contact us at{' '}
                  <a className="link" href={`mailto:${supportEmail}`}>
                    {supportEmail}
                  </a>{' '}
                  if you think this is a mistake.
                </span>
              </div>
            )}

            {status === 'preConditionFailed' && (
              <div className="font-bold text-red-dark flex items-center space-x-sm">
                <AlertIcon width="1.5rem" height="1.5rem" />
                <span>
                  Your Donation Partner registration with CDS VIC is incomplete. Please complete the registration and
                  return to us.
                </span>
              </div>
            )}

            {status === 'failed' && (
              <div className="font-bold text-red-dark flex items-center space-x-sm">
                <AlertIcon width="1.5rem" height="1.5rem" />
                <span>Something went wrong. Please try again.</span>
              </div>
            )}
          </form>
        </>
      )}
    </div>
  )
}
