import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import { Dialog } from './Dialog'
import { FileUpload } from './FileUpload'
import { ZoomInIcon, ZoomOutIcon } from '../shared'
import { THEME } from '../../lib'

const browserNotSupported = !!window.navigator.userAgent.toLowerCase().match(/(edge|trident)/g)
const { displayName } = THEME

type Props = {
  uploadedFileName: string | undefined
  children: React.ReactNode
  onChange: (file: File | Blob, name: string) => void
  requestStatus: RequestStatus
  id: string
  required?: boolean
}

export const ImageUpload = ({ uploadedFileName, children, onChange, requestStatus, id, required }: Props) => {
  const [processingImage, setProcessingImage] = useState<HTMLImageElement | null>(null)
  const [processingImageFile, setProcessingImageFile] = useState<File | null>(null)
  const [croppedImageFile, setCroppedImageFile] = useState<File | Blob | null>(null)
  const [showCropDialog, setShowCropDialog] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [cropCoordinates, setCropCoordinates] = useState({ x: 0, y: 0 })
  const [cropZoom, setCropZoom] = useState(0.5)
  const [backgroundColor, setBackgroundColor] = useState('white')

  const closeCropDialog = () => {
    setErrorMsg('')
    setShowCropDialog(false)
  }

  const onFileSelected = (uploadedFile: File) => {
    const fr = new FileReader()

    fr.onload = () => {
      const img = new Image()

      img.onload = () => {
        const fileSizeMb = (img.src.length * 0.75) / 1000000

        if (img.width < 500 || img.height < 500) {
          setErrorMsg(
            `Logo must have a width and height of at least 500px. Your uploaded logo is ${img.width}x${img.height}.`
          )
        } else if (fileSizeMb > 2) {
          setErrorMsg('File size cannot exceed 2 megabytes.')
        } else if (browserNotSupported && img.width !== img.height) {
          setErrorMsg('Height and width must be identical (i.e a square)')
        } else if (browserNotSupported) {
          onChange(uploadedFile, uploadedFile.type)
        } else {
          setErrorMsg('')
          setProcessingImage(img)
          setProcessingImageFile(uploadedFile)
          setShowCropDialog(true)
        }
      }
      // @ts-ignore
      img.src = fr.result
    }

    fr.readAsDataURL(uploadedFile)
  }

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    const croppedWidth = croppedAreaPixels.width
    const croppedHeight = croppedAreaPixels.height

    const canvas = document.createElement('canvas')
    canvas.width = croppedWidth
    canvas.height = croppedHeight
    const context = canvas?.getContext('2d')

    if (context) {
      context.fillStyle = backgroundColor
      context.fillRect(0, 0, croppedWidth, croppedHeight)
    }

    const sourceX = croppedAreaPixels.x
    const sourceY = croppedAreaPixels.y
    const sourceWidth = croppedWidth
    const sourceHeight = croppedHeight
    const destWidth = croppedWidth
    const destHeight = croppedHeight
    const destX = 0
    const destY = 0

    context?.drawImage(
      processingImage!,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      destX,
      destY,
      destWidth,
      destHeight
    )

    canvas.toBlob(setCroppedImageFile, processingImageFile?.type)
  }

  const onDone = () => {
    closeCropDialog()
    onChange(croppedImageFile!, processingImageFile!.name)
  }

  return (
    <FileUpload
      id={id}
      uploadedFileName={uploadedFileName}
      onChange={onFileSelected}
      requestStatus={requestStatus}
      errorMsg={errorMsg}
      acceptedFileTypes="image/png, image/jpeg, image/jpg"
      required={required}
    >
      {children}

      {browserNotSupported && (
        <div className="alert alert-warning my-sm">
          Your browser doesn&apos;t support image cropping, so uploaded logo must be a perfect square.
        </div>
      )}

      {showCropDialog && (
        <Dialog>
          <div className="relative w-[75vw] h-[75vh] animate-slideIn">
            <Cropper
              image={processingImage?.src}
              crop={cropCoordinates}
              aspect={1}
              onCropChange={setCropCoordinates}
              onCropComplete={onCropComplete}
              onZoomChange={setCropZoom}
              minZoom={0.2}
              cropShape="round"
              zoom={cropZoom}
              restrictPosition={false}
              style={{ containerStyle: { backgroundColor } }}
              showGrid={false}
            />
          </div>

          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 z-10 bg-white rounded-half mb-md shadow-lg w-full p-md flex flex-col items-center md:flex-row md:w-auto md:space-x-4">
            <div className="btn rounded-none border-0 shadow-none">
              <div className="peer">Need help?</div>
              <div className="peer-hover:block transition opacity-0 peer-hover:opacity-100 absolute -top-lg z-10 text-white rounded-half p-md shadow-lg bg-midnight text-sm left-0">
                Place your logo within the circle by moving it with your cursor and/or zooming in and out. This will
                reflect how your logo will look like in the {displayName} app.
              </div>
            </div>

            <div className="flex items-center justify-center w-full">
              <div className="mr-sm">Background</div>
              <button
                aria-label="Set image background to black"
                className="btn btn-icon !p-xs"
                onClick={() => {
                  setBackgroundColor('black')
                  // in order to trigger onCropComplete which triggers canvas background to change
                  setCropZoom(cropZoom + 0.0001)
                }}
              >
                <div
                  className={`w-2 h-2 rounded bg-black border ${
                    backgroundColor === 'black' ? 'outline outline-red-transparent' : ''
                  }`}
                />
              </button>
              <button
                aria-label="Set image background to white"
                className="btn btn-icon !p-xs"
                onClick={() => {
                  setBackgroundColor('white')
                  // in order to trigger onCropComplete which triggers canvas background to change
                  setCropZoom(cropZoom + 0.0001)
                }}
              >
                <div
                  className={`w-2 h-2 rounded bg-white border ${
                    backgroundColor === 'white' ? 'outline outline-red-transparent' : ''
                  }`}
                />
              </button>
            </div>

            <div className="flex justify-center w-full">
              <button
                aria-label="Zoom out"
                className="btn btn-icon"
                onClick={() => cropZoom >= 0.2 && setCropZoom(cropZoom - 0.1)}
              >
                <ZoomOutIcon width="2rem" height="2rem" />
              </button>
              <button aria-label="Zoom in" className="btn btn-icon" onClick={() => setCropZoom(cropZoom + 0.1)}>
                <ZoomInIcon width="2rem" height="2rem" />
              </button>
            </div>

            <div className="flex space-x-md w-full">
              <button className="btn w-full" onClick={closeCropDialog}>
                Cancel
              </button>

              <button className="btn btn-primary-dark w-full" disabled={croppedImageFile === null} onClick={onDone}>
                Done
              </button>
            </div>
          </div>
        </Dialog>
      )}
    </FileUpload>
  )
}
