import React from 'react'
import { TermsAndConditionsNSWFull } from './schemes/TermsAndConditionsNSWFull'
import { TermsAndConditionsVICFull } from './schemes/TermsAndConditionsVICFull'
import { THEME } from '../../lib'

const { stateShort } = THEME

export const TermsAndConditionsPage = () => {
  return stateShort === 'NSW' ? <TermsAndConditionsNSWFull /> : <TermsAndConditionsVICFull />
}
