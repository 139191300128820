import React, { FunctionComponent, useEffect, useState } from 'react'
import { CloseIcon, LogoutIcon, NavIcon } from '../shared'
import { THEME } from '../../lib'
import { NavLink, useLocation } from 'react-router-dom'

type Props = {
  isAuthenticated: 'yes' | 'no' | 'toBeDecided'
  logout: () => void
}

export const SmallScreenNavBar: FunctionComponent<Props> = ({ isAuthenticated, logout }) => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)

  const { pathname } = useLocation()

  useEffect(() => {
    if (isSideMenuOpen) {
      setIsSideMenuOpen(false)
    }
  }, [pathname])

  return (
    <>
      <div className="lg:hidden flex items-center justify-between">
        {isAuthenticated && (
          <div>
            <button
              aria-label="Open menu"
              className="p-md transition active:scale-95"
              onClick={() => setIsSideMenuOpen(prevState => !prevState)}
            >
              <NavIcon width="2rem" height="2rem" />
            </button>
          </div>
        )}

        <div className="font-bold">{THEME.displayName} Charity</div>

        <div>
          <button className="p-md transition active:scale-95" onClick={logout}>
            <LogoutIcon width="2rem" height="2rem" />
          </button>
        </div>
      </div>

      {isAuthenticated && (
        <>
          <div
            className={`lg:hidden absolute transition delay-75 ${
              isSideMenuOpen ? '-translate-x-[1rem]' : '-translate-x-[100%]'
            } card w-2/3 h-full top-0 left-0 z-10 pl-xl pr-lg py-lg flex flex-col space-y-lg`}
          >
            <div className="flex items-start justify-between">
              <div className="text-lg">Menu</div>

              <button
                aria-label="Close menu"
                className="transition active:scale-95"
                onClick={() => setIsSideMenuOpen(prevState => !prevState)}
              >
                <CloseIcon width="1.5rem" height="1.5rem" />
              </button>
            </div>

            <ul className="flex flex-col space-y-md">
              <li>
                <NavLink to="/" className="[&.active]:underline">
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink to="/tos" className="[&.active]:underline">
                  Terms and Conditions
                </NavLink>
              </li>
              <li>
                <NavLink to="/profile" className="[&.active]:underline">
                  Profile
                </NavLink>
              </li>
            </ul>
          </div>
          <div
            className={`lg:hidden ${
              isSideMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
            } fixed top-0 left-0 w-full h-full bg-black-transparent z-[1]`}
          ></div>
        </>
      )}
    </>
  )
}
