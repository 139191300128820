import React, { FunctionComponent, useState } from 'react'
import { addMonths, isAfter, isBefore, subMonths } from 'date-fns'
import { CheckmarkIcon, CloseIcon, Datepicker, Dialog, Label, TimeIcon } from '../../../../components/shared'
import { THEME } from '../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { extendCharityCampaign } from '../../../../services'

const { supportEmail } = THEME

type Props = {
  charityId: string
  campaignId: string
  endTime: string
}

export const ExtendCampaign: FunctionComponent<Props> = ({ charityId, campaignId, endTime }) => {
  const [showExtendDialog, setShowExtendDialog] = useState(false)
  const [extendRequestStatus, setExtendRequestStatus] = useState<RequestStatus | 'validationFailed'>('initial')
  const minEndDate = addMonths(new Date(endTime), 1)
  const maxEndDate = addMonths(new Date(endTime), 6)
  const [newEndDate, setNewEndDate] = useState(minEndDate)
  const [showCustomize, setShowCustomize] = useState(false)
  const [error, setError] = useState('')

  const campaignEndDate = new Date(endTime)
  const oneMonthBeforeEndTime = subMonths(campaignEndDate, 1)
  const campaignExpiresSoon = isAfter(new Date(), oneMonthBeforeEndTime)
  const campaignHasNotEnded = isBefore(new Date(), campaignEndDate)

  const extendCampaign = async () => {
    try {
      setError('')
      setExtendRequestStatus('loading')
      await extendCharityCampaign(charityId, campaignId, newEndDate)
      setExtendRequestStatus('success')

      setTimeout(() => {
        setShowExtendDialog(false)
        window.location.reload()
      }, 2500)
    } catch (error: any) {
      if (error.status === 400) {
        setError(error.body.detail ? error.body.detail : 'Failed to update campaign')
        setExtendRequestStatus('initial')
      } else {
        logError(new Error('Failed to extend campaign'), error)
        setExtendRequestStatus('failed')
      }
    }
  }

  const extendBy6Months = () => {
    setNewEndDate(maxEndDate)
    extendCampaign()
  }

  return campaignExpiresSoon && campaignHasNotEnded ? (
    <>
      <button className="btn btn-primary-dark" onClick={() => setShowExtendDialog(true)}>
        Extend
      </button>

      {showExtendDialog && (
        <Dialog>
          <div className="mx-md card max-w-25 space-y-lg p-xl animate-slideIn relative [&>svg]:w-full">
            {extendRequestStatus === 'success' ? (
              <>
                <CheckmarkIcon width="4rem" height="4rem" color="var(--colors-green)" />
                <h1 className="text-lg text-center">Your campaign has been successfully updated</h1>
              </>
            ) : (
              <>
                <button
                  aria-label="Close"
                  className="btn btn-icon absolute top-lg right-lg"
                  onClick={() => setShowExtendDialog(false)}
                >
                  <CloseIcon width="1rem" height="1rem" />
                </button>

                <TimeIcon width="4rem" height="4rem" />

                <h1 className="text-xl text-center">Extend your campaign</h1>
                <p>Any issues or queries, please contact {supportEmail}.</p>

                <div className="flex flex-col justify-center">
                  {extendRequestStatus === 'loading' ? (
                    <div className="self-center loadingSpinner" />
                  ) : showCustomize ? (
                    <>
                      <Label htmlFor="datepicker">Set new end date</Label>
                      <Datepicker
                        id="datepicker"
                        value={newEndDate}
                        onChange={setNewEndDate}
                        min={minEndDate}
                        max={maxEndDate}
                        required
                      />

                      <button className="btn btn-primary-dark w-full mt-md" onClick={extendCampaign}>
                        Update
                      </button>
                    </>
                  ) : (
                    <>
                      <button className="btn btn-primary-dark w-full" onClick={extendBy6Months}>
                        Extend 6 months
                      </button>

                      <button className="btn btn-icon" onClick={() => setShowCustomize(true)}>
                        <span className="border-b-2">Customize</span>
                      </button>
                    </>
                  )}

                  {extendRequestStatus === 'failed' && (
                    <>
                      <p className="text-red-dark">Something went wrong. Please try again.</p>
                      <p className="text-red-dark mt-md">If the issue persists, please contact us at {supportEmail}.</p>
                    </>
                  )}

                  {error && <p className="text-red-dark mt-md">{error}</p>}
                </div>
              </>
            )}
          </div>
        </Dialog>
      )}
    </>
  ) : null
}
