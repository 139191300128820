import React, { useEffect, useMemo, useState } from 'react'
import { CharityFormSkeleton } from './common/CharityFormSkeleton'
import { CharityForm } from '../../components/charities/CharityForm'
import { auth } from '../../firebase'
import { fetchCharity, fetchPendingChangesForCharity, updateCharity } from '../../services'
import { deleteDraft, getOrCreateDraft } from '../../services/firestore.service'
import { logError } from '@tomra/datadog-browser-logging'
import { useNavigate, useParams } from 'react-router-dom'
import { CheckmarkIcon, Dialog } from '../../components/shared'
import { parseAttachmentErrors } from '../../lib/charity'
import { ErrorCard } from '../../components/shared/ErrorCard'
import { DisabledCharity } from '../dashboard/common/DisabledCharity'

export const UpdateCharityPage = () => {
  const adminId = useMemo(() => auth.currentUser!.uid, [])
  const { charityId } = useParams() as { charityId: string }
  const navigate = useNavigate()
  const [draft, setDraft] = useState<Draft>()
  const [requestStatus, setRequestStatus] = useState<RequestStatus>('initial')
  const [submitRequestStatus, setSubmitRequestStatus] = useState<RequestStatus>('initial')
  const [submitError, setSubmitError] = useState('')
  const [existingBankingData, setExistingBankingData] = useState<BankingData>()
  const [pendingChanges, setPendingChanges] = useState<Charity | object>({})
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    setRequestStatus('loading')

    Promise.all([fetchCharity(charityId), fetchPendingChangesForCharity(charityId)])
      .then(([charity, pendingChanges]) => {
        setPendingChanges(pendingChanges)
        return { ...charity, ...pendingChanges, logoUrl: charity.logoUrl }
      })
      .then(charity => {
        setIsDisabled(charity.status === 'DISABLED')
        setExistingBankingData(charity.bankingData)
        return getOrCreateDraft(adminId, charity)
      })
      .then(data => {
        setDraft(data)
        setRequestStatus('initial')
      })
      .catch(error => {
        setRequestStatus('failed')
        logError(new Error('Failed to fetch charity'), error)
      })
  }, [adminId])

  const updateExistingCharity = async (charity: Charity) => {
    try {
      setSubmitRequestStatus('loading')

      await updateCharity({
        id: charityId,
        organization: charity.organization,
        bankingData: charity.bankingData,
        primaryContact: charity.primaryContact,
        secondaryContact: charity.secondaryContact || null
      })

      await deleteDraft(adminId)

      setSubmitRequestStatus('success')
    } catch (error: any) {
      if (error.status === 412) {
        setSubmitRequestStatus('preConditionFailed')
        setSubmitError(parseAttachmentErrors(error?.body?.detail))
      } else {
        setSubmitRequestStatus('failed')
        logError(new Error('Failed to update charity'), error)
      }
    }
  }

  return isDisabled ? (
    <DisabledCharity />
  ) : requestStatus === 'failed' ? (
    <ErrorCard />
  ) : draft ? (
    <>
      {Object.keys(pendingChanges).length > 0 && (
        <div className="alert alert-warning w-2/3 flex flex-col space-y-md items-start">
          <h2 className="text-md font-bold">You have changes waiting for review</h2>
          <p>Any new changes you submit will be added to the existing changes.</p>
        </div>
      )}

      <CharityForm
        adminId={adminId}
        originalBankingData={existingBankingData as BankingData}
        draft={draft as Draft}
        onSubmit={updateExistingCharity}
        submitRequestStatus={submitRequestStatus}
        submitError={submitError}
      >
        <h1 className="text-xl mb-lg">Edit charity</h1>
        <p className="prose">
          You can update your charity information. Changes will be pending until reviewed by TOMRA.
        </p>
      </CharityForm>

      {submitRequestStatus === 'success' && (
        <Dialog>
          <div className="card flex flex-col items-center p-xl animate-fadeIn">
            <CheckmarkIcon width="4rem" height="4rem" color="var(--colors-green)" />
            <h1 className="text-xl mt-md">Excellent!</h1>
            <p className="my-lg">Your changes has been submitted for review.</p>

            <button className="btn" onClick={() => navigate(`/`)}>
              Ok
            </button>
          </div>
        </Dialog>
      )}
    </>
  ) : (
    <CharityFormSkeleton />
  )
}
