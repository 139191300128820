import React, { SyntheticEvent, useEffect, useState } from 'react'
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { auth } from '../../firebase'
import { AlertIcon } from '../../components/shared'
import { logError } from '@tomra/datadog-browser-logging'
import { THEME } from '../../lib'
import { useNavigate } from 'react-router-dom'
import { sendSignInMetric, trackSignInLink, triggerSendMagicLink } from '../../services/auth.service'
import { firebaseAuthErrorMap } from '../../firebaseAuthErrorMap'
import { LoginForm } from './common/LoginForm'
import { MagicLinkSent } from './common/MagicLinkSent'
import charityHeart from '../../images/charity-heart.svg'
import { ampli } from '../../ampli'

const LOCAL_STORAGE_EMAIL_KEY = 'charityEmailForSignIn'

const { displayName } = THEME

export const LoginPage = () => {
  const [status, setStatus] = useState<LoginRequestStatus>('idle')
  const [authError, setAuthError] = useState<string>('')
  const [email, setEmail] = useState(localStorage.getItem(LOCAL_STORAGE_EMAIL_KEY) || '')

  const navigate = useNavigate()

  const login = async (email: string) => {
    try {
      setAuthError('')
      await signInWithEmailLink(auth, email, window.location.href)
      await sendSignInMetric(window.location.href)
      ampli.userIsSignedIn()
      navigate('/')
    } catch (error: any) {
      if (error && Object.hasOwn(error, 'code')) {
        setAuthError(error.code)
      }

      setStatus('failure')
      logError(new Error('Sign in failed'), error as Error)
    }
  }

  useEffect(() => {
    const signInUser = async () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        trackSignInLink(window.location.href)

        try {
          setStatus('signing_in')

          const localEmail = localStorage.getItem(LOCAL_STORAGE_EMAIL_KEY) as string

          if (!localEmail) {
            setStatus('confirm_email')
          } else {
            await login(localEmail)
          }
        } catch (error: unknown) {
          setStatus('failure')
          logError(new Error('Unable to sign in user'), error as Error)
        }
      }
    }

    signInUser()
  }, [navigate])
  const sendMagicLink = async (e: SyntheticEvent) => {
    e.preventDefault()

    setStatus('sending')

    try {
      await triggerSendMagicLink(email)
      localStorage.setItem(LOCAL_STORAGE_EMAIL_KEY, email)
      setStatus('link_sent')
      ampli.sendSignInLink()
    } catch (error: unknown) {
      setStatus('failure')
      logError(new Error('Unable to send magic login link', error as Error))
    }
  }

  return (
    <>
      {status === 'signing_in' ? (
        <div className="w-full mt-[30%] text-center">
          <div className="loadingSpinner" />
          <h1 className="text-lg text-midnight mt-md">Signing you in...</h1>
        </div>
      ) : status === 'link_sent' || status === 'sending' ? (
        <MagicLinkSent email={email} status={status} goBack={() => setStatus('idle')} />
      ) : status === 'confirm_email' ? (
        <div className="p-xl mx-auto min-w-screen-sm max-w-screen-sm flex flex-col space-y-md">
          <h1 className="text-xl m-0">Please confirm your e-mail address</h1>
          <p>Use the same e-mail where you received the sign in link.</p>

          <LoginForm
            email={email}
            setEmail={setEmail}
            buttonLabel="Confirm e-mail"
            onSubmit={e => {
              e.preventDefault()
              login(email)
            }}
          />
        </div>
      ) : (
        <div className="p-xl mx-auto min-w-screen-sm max-w-screen-sm flex flex-col space-y-md">
          <img src={charityHeart} alt="Charity Heart illustration" className="w-[18.75rem] h-[18.75rem] mx-auto" />

          <h1 className="text-xl text-center">Welcome to {displayName} Charity</h1>
          <p className="text-center">Simply enter your email and we&apos;ll send a login link to your inbox.</p>

          {status === 'failure' && (
            <div className="my-md flex items-start space-x-md not-prose card p-lg">
              <div className="text-red-dark mt-xs">
                <AlertIcon width="1.5rem" height="1.5rem" />
              </div>
              <div>
                <h2 className="font-bold text-red-dark text-md">
                  {authError ? firebaseAuthErrorMap[authError]?.title : 'Something went wrong.'}
                </h2>
                <p>{authError ? firebaseAuthErrorMap[authError]?.message : 'Please try again.'}</p>
              </div>
            </div>
          )}

          <LoginForm buttonLabel="Send me a link!" email={email} setEmail={setEmail} onSubmit={sendMagicLink} />
        </div>
      )}
    </>
  )
}
