import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Label, Datepicker, Dialog, CheckmarkIcon } from '../shared'
import { logError } from '@tomra/datadog-browser-logging'
import { submitCampaign, fetchCampaign, updateCampaign } from '../../services'
import { CurrencyInputField } from '../shared/CurrencyInputField'
import { validateCampaign } from '../../lib/campaign'
import { FormValidationError } from '../shared/FormValidationError'
import { addDays, addMonths, addWeeks, differenceInDays } from 'date-fns'
import { ValidationError } from 'yup'
import { findObjectDifference } from '../../pages/charity/common/findObjectDifference'

const newCampaignId = uuidv4()
const minStartDate = addWeeks(new Date(), 2)

const campaignInit: Campaign = {
  campaignId: '',
  name: '',
  description: '',
  startTime: minStartDate,
  endTime: addDays(minStartDate, 365),
  financialTarget: 0,
  engagementPlan: '',
  createdAt: '',
  charityId: '',
  campaignCode: '',
  status: 'NEW',
  logoUrl: '',
  instagramHandle: '',
  youtubeHandle: '',
  tiktokHandle: '',
  facebookHandle: '',
  snapchatHandle: '',
  linkedinHandle: ''
}

export const CampaignForm = () => {
  const navigate = useNavigate()
  const [campaign, setCampaign] = useState<Campaign>(campaignInit)
  const [fetchRequestStatus, setFetchRequestStatus] = useState<RequestStatus>('initial')
  const [submitRequestStatus, setSubmitRequestStatus] = useState<RequestStatus>('initial')
  const { charityId: pathCharityId, campaignId: pathCampaignId } = useParams() as {
    charityId: string
    campaignId?: string
  }
  const [campaignReference, setCampaignReference] = useState<Campaign>(campaignInit)
  const [error, setError] = useState('')
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const [duration, setDuration] = useState(365)

  const diff = useMemo(() => findObjectDifference(campaignReference, campaign), [campaign])
  const campaignHasChanges = Object.keys(diff).length > 0

  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (campaignHasChanges) {
      setDuration(differenceInDays(new Date(campaign.endTime), new Date(campaign.startTime)))
      const errors = validateCampaign(campaign)
      setValidationErrors(errors)
    }
  }, [campaign])

  const _fetchCampaign = useCallback(async (charityId: string, campaignId: string) => {
    try {
      setFetchRequestStatus('loading')
      const campaign = await fetchCampaign(charityId, campaignId)
      setCampaignReference(campaign)
      setCampaign(campaign)
      setFetchRequestStatus('success')
    } catch (error: any) {
      logError(new Error('Failed to fetch existing campaign'), error)
      setFetchRequestStatus('failed')
    }
  }, [])

  const onSubmit = async (evt: any) => {
    evt.preventDefault()

    setError('')

    if (pathCampaignId) {
      return updateExistingCampaign()
    } else {
      return createNewCampaign()
    }
  }

  const createNewCampaign = async () => {
    try {
      setSubmitRequestStatus('loading')
      await submitCampaign(pathCharityId, { ...campaign, campaignId: newCampaignId, status: 'NEW' })
      setSubmitRequestStatus('success')
    } catch (error: any) {
      if (error.status === 400) {
        setError(error.body.detail ? error.body.detail : 'Something went wrong. Please try again.')
      } else {
        setError('Something went wrong. Please try again.')
      }

      setSubmitRequestStatus('failed')
      logError(new Error('Failed to create new campaign'), error)
    }
  }

  const updateExistingCampaign = async () => {
    try {
      setSubmitRequestStatus('loading')
      await updateCampaign(pathCharityId, campaign)
      setSubmitRequestStatus('success')
    } catch (error: any) {
      setSubmitRequestStatus('failed')
      logError(new Error('Failed to update new campaign'), error)

      if (error.status === 400) {
        setError(error.body.detail ? error.body.detail : 'Something went wrong. Please try again.')
      } else {
        setError('Something went wrong. Please try again.')
      }
    }
  }

  useEffect(() => {
    if (pathCampaignId || searchParams.get('copy')) {
      const idToFetch = searchParams.get('copy') || pathCampaignId
      _fetchCampaign(pathCharityId, idToFetch as string)
    }
  }, [_fetchCampaign, pathCharityId, pathCampaignId])

  return fetchRequestStatus === 'loading' ? (
    <div className="centerAbsolute">
      <div className="loadingSpinner" data-testid="loading" />
    </div>
  ) : fetchRequestStatus === 'failed' ? (
    <h1 className="centerAbsolute alert alert-danger">Something went wrong. Please try again later</h1>
  ) : (
    <form onSubmit={onSubmit} className="card space-y-xl mx-auto my-lg p-lg max-w-screen-sm">
      <h1 className="text-xl">{pathCampaignId ? 'Edit fundraising campaign' : 'Create fundraising campaign'}</h1>

      <div>
        <Label htmlFor="name">Campaign name</Label>

        <input
          type="text"
          id="name"
          value={campaign.name}
          onChange={evt => setCampaign({ ...campaign, name: evt.target.value })}
          maxLength={60}
          required
        />
        <p className="text-sm">{campaign.name.length}/60 characters</p>
      </div>

      <div>
        <Label
          htmlFor="description"
          tooltipContent="To help supporters engage with your campaign, be specific, transparent and make it relevant to your community. What are you raising money for? How will the funds raised will benefit your community?"
        >
          Description
        </Label>

        <textarea
          id="description"
          placeholder="Example: Our organisation is raising funds to purchase items for the care and comfort of patients of our local Hospital, including Maternity, emergency and operating theatre patients."
          maxLength={500}
          value={campaign.description}
          onChange={evt => setCampaign({ ...campaign, description: evt.target.value })}
          required
        />
        <p className="text-sm">{campaign.description.length}/500 characters</p>
      </div>

      <div className="grid grid-cols-3 items-end gap-x-md">
        <div className="col-span-1">
          <Label
            htmlFor="startTime"
            tooltipContent="Setting a visible deadline is a powerful motivator for donors. But allow a reasonable amount of time to achieve your goals. Your deadline, too, should be ambitious but attainable."
          >
            Start date
          </Label>

          <Datepicker
            id="startTime"
            value={new Date(campaign.startTime)}
            onChange={date => setCampaign({ ...campaign, startTime: date })}
            min={minStartDate}
            required
          />
        </div>

        <div className="col-span-1">
          <Label
            htmlFor="endTime"
            tooltipContent="Minimum 3 months and maximum 1 year from the date you want to start your fundraising."
          >
            End date
          </Label>

          <Datepicker
            id="endTime"
            value={new Date(campaign.endTime)}
            onChange={date => setCampaign({ ...campaign, endTime: date })}
            min={addMonths(new Date(), 1)}
            max={addMonths(new Date(), 12)}
            required
          />
        </div>

        <div className="col-span-1 text-sm mb-md">{duration > 0 ? duration : 0} days</div>

        <div className="col-span-3">
          <FormValidationError field="startTime" errors={validationErrors} />
          <FormValidationError field="endTime" errors={validationErrors} />
        </div>
      </div>

      <div>
        <Label
          htmlFor="financialTarget"
          tooltipContent="Set a target that is both Ambitious and Attainable (min $100 and max $1 mill). It will keep you motivated and will inspire your supporters and the community to help you reach it, just that little bit more. And remember one eligible bottle or can donated is worth 10 cents so set a target that is challenging, but not impossible to reach."
        >
          Financial target
        </Label>

        <CurrencyInputField
          value={!isNaN(campaign.financialTarget) ? campaign.financialTarget : 0}
          onChange={value => setCampaign({ ...campaign, financialTarget: value })}
        />

        {Object.hasOwn(diff, 'financialTarget') && (
          <FormValidationError field="financialTarget" errors={validationErrors} />
        )}
      </div>

      <div>
        <Label htmlFor="engagementPlan">Awareness and Engagement plan</Label>

        <textarea
          id="engagementPlan"
          className="textarea"
          placeholder="List activities that will raise awareness of your fundraising campaign, describe how you will engage with all your networks (staff, volunteers, supporters, local businesses, corporate donors, ...) and also engage the wider community to maximise the donations."
          maxLength={1000}
          value={campaign.engagementPlan}
          onChange={evt => setCampaign({ ...campaign, engagementPlan: evt.target.value })}
          required
        />
        <p className="text-sm">{campaign.engagementPlan.length}/1000 characters</p>
      </div>

      <div className="flex items-center justify-end space-x-md">
        <button type="button" className="btn" onClick={() => navigate('/')}>
          Cancel
        </button>

        <button
          className="btn btn-primary-dark flex space-x-sm items-center"
          disabled={submitRequestStatus === 'loading' || validationErrors.length > 0 || !campaignHasChanges}
          aria-disabled={submitRequestStatus === 'loading'}
        >
          {submitRequestStatus === 'loading' && <span className="loadingSpinner" />}
          <span>{pathCampaignId ? 'Update' : 'Submit'}</span>
        </button>
      </div>
      {submitRequestStatus === 'failed' && <p className="text-red text-right">{error}</p>}

      {submitRequestStatus === 'success' && (
        <Dialog>
          <div className="card flex flex-col items-center p-xl animate-fadeIn">
            <CheckmarkIcon width="4rem" height="4rem" color="var(--colors-green)" />
            <h1 className="text-xl">Great!</h1>
            <p className="my-lg">
              {pathCampaignId
                ? 'Your changes has been submitted and should be processed within 10 business days.'
                : 'Your campaign has been submitted and should be processed within 10 business days.'}
            </p>
            <button className="btn" onClick={() => navigate('/')}>
              Close
            </button>
          </div>
        </Dialog>
      )}
    </form>
  )
}
