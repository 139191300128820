import React from 'react'
import { InfoTooltip, Label, Accordion } from '../'
import { geoFootprintToReadableString } from './enumsToReadableStrings'

type Props = {
  organization: Organization
  onOrganizationFieldsChange: (organization: Partial<Organization>) => void
}

export const Location = ({ organization, onOrganizationFieldsChange }: Props) => (
  <Accordion title="Location and operational footprint">
    <div>
      <Label htmlFor="streetAddress">Street address</Label>

      <input
        type="text"
        id="streetAddress"
        maxLength={35}
        value={organization.streetAddress}
        onChange={evt => onOrganizationFieldsChange({ streetAddress: evt.target.value })}
        required
      />
      <p>{organization.streetAddress.length}/35 characters</p>
    </div>

    <div>
      <Label htmlFor="suburb">Suburb</Label>

      <input
        type="text"
        id="suburb"
        value={organization.suburb}
        onChange={evt => onOrganizationFieldsChange({ suburb: evt.target.value })}
      />
    </div>

    <div>
      <Label htmlFor="postCode">Postcode</Label>

      <input
        type="text"
        id="postCode"
        value={organization.postCode}
        onChange={evt => onOrganizationFieldsChange({ postCode: evt.target.value })}
        required
      />
    </div>

    <fieldset className="space-y-md [&>div]:flex [&>div]:align-center [&>div>input]:mr-md [&>hr]:border-[var(--colors-fog)]">
      <Label>Describe your operational footprint</Label>

      <div>
        <input
          type="radio"
          className="radio"
          id="geoFootprint1"
          value="LOCAL"
          name="geoFootprint"
          checked={organization.geoFootprint === 'LOCAL'}
          onChange={(evt: any) => onOrganizationFieldsChange({ geoFootprint: evt.target.value })}
          required
        />
        <label htmlFor="geoFootprint1">{geoFootprintToReadableString['LOCAL']}</label>
        <InfoTooltip content="Local must be a school, sports club, local not-for-profit organisation or a community group with a strong local presence that can demonstrate a broad community benefit, within the local area, in which a reverse vending machine(s) is located." />
      </div>

      <hr />

      <div>
        <input
          type="radio"
          className="radio"
          id="geoFootprint2"
          value="STATE"
          name="geoFootprint"
          checked={organization.geoFootprint === 'STATE'}
          onChange={(evt: any) => onOrganizationFieldsChange({ geoFootprint: evt.target.value })}
          required
        />
        <label htmlFor="geoFootprint2">{geoFootprintToReadableString['STATE']}</label>
      </div>

      <hr />

      <div>
        <input
          type="radio"
          className="radio"
          id="geoFootprint3"
          value="NATIONAL"
          name="geoFootprint"
          checked={organization.geoFootprint === 'NATIONAL'}
          onChange={(evt: any) => onOrganizationFieldsChange({ geoFootprint: evt.target.value })}
          required
        />
        <label htmlFor="geoFootprint3">{geoFootprintToReadableString['NATIONAL']}</label>
      </div>
    </fieldset>
  </Accordion>
)
