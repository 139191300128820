import React, { useEffect, useMemo, useState } from 'react'
import { CharityForm } from '../../components/charities/CharityForm'
import { THEME } from '../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { CharityFormSkeleton } from './common/CharityFormSkeleton'
import { fetchCharities, submitCharity } from '../../services'
import { deleteDraft, getOrCreateDraft } from '../../services/firestore.service'
import { auth } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import { DonationPartnerForm } from './common/DonationPartnerForm'
import { CheckmarkIcon, Dialog } from '../../components/shared'
import { parseAttachmentErrors } from '../../lib/charity'

const { displayName, stateShort } = THEME

export const CreateCharityPage = () => {
  const adminId = useMemo(() => auth.currentUser!.uid, [])
  const navigate = useNavigate()
  const [draft, setDraft] = useState<Draft>()
  const [submitRequestStatus, setSubmitRequestStatus] = useState<RequestStatus>('initial')
  const [submitError, setSubmitError] = useState('')

  const shouldShowDonationPartnerForm = stateShort === 'VIC' && !draft?.charity.organization.organizationAbn

  const setOrganizationChanges = (organization: Partial<Organization>) => {
    setDraft(prevState => {
      return {
        ...prevState,
        charity: {
          ...(prevState as Draft).charity,
          organization: { ...prevState?.charity.organization, ...organization }
        }
      } as Draft
    })
  }

  useEffect(() => {
    const f = fetchCharities()

    f.run()
      .then(charities => {
        if (charities.length > 0) {
          return navigate(`/charity/${charities[0].id}`)
        }

        getOrCreateDraft(adminId, null).then(data => {
          setDraft(data)
        })
      })
      .catch(error => {
        logError(new Error('Failed to fetch campaigns'), error)
        throw error
      })

    return () => f.abort()
  }, [adminId])

  const createCharity = async (charity: Charity) => {
    try {
      setSubmitError('')
      setSubmitRequestStatus('loading')

      await submitCharity({
        id: charity.id,
        organization: charity.organization,
        bankingData: charity.bankingData,
        primaryContact: charity.primaryContact,
        secondaryContact: charity.secondaryContact || null,
        logoUrl: charity.logoUrl as string
      })

      await deleteDraft(adminId)

      setSubmitRequestStatus('success')
    } catch (error: any) {
      if (error.status === 412) {
        setSubmitRequestStatus('preConditionFailed')
        setSubmitError(parseAttachmentErrors(error?.body?.detail))
      } else {
        setSubmitRequestStatus('failed')
        logError(new Error('Failed to create new charity'), error)
      }
    }
  }

  return shouldShowDonationPartnerForm && draft ? (
    <DonationPartnerForm
      onConfirmDetails={donationPartnerDetails => {
        const { donationPartnerId, legalName, businessRegistrationNumber } = donationPartnerDetails

        setOrganizationChanges({
          externalId: donationPartnerId,
          organizationAbn: businessRegistrationNumber,
          name: legalName
        })
      }}
    />
  ) : draft ? (
    <>
      <CharityForm
        adminId={adminId}
        originalBankingData={null}
        draft={draft as Draft}
        onSubmit={createCharity}
        submitRequestStatus={submitRequestStatus}
        submitError={submitError}
      >
        <h1 className="text-lg md:text-xl mb-lg">Fundraising through the {displayName} app</h1>
        <div className="prose-sm">
          <p>
            Before you can be part of the {displayName} app charity program, we need to know a bit about your charity.
            Fill out the form below and someone from our team will review your application.
          </p>
          <p>
            All changes are automatically saved as draft. You can come back and complete your application at any time.
          </p>
        </div>
      </CharityForm>

      {submitRequestStatus === 'success' && (
        <Dialog>
          <div className="card flex flex-col items-center p-xl animate-fadeIn">
            <CheckmarkIcon width="4rem" height="4rem" color="var(--colors-green)" />
            <h1 className="text-xl">Almost there!</h1>
            <p className="my-lg">Now let&apos;s add a campaign.</p>
            <button
              className="btn btn-primary-dark w-full"
              onClick={() => navigate(`/charity/${(draft as Draft).charity.id}/campaign`)}
            >
              Continue
            </button>
          </div>
        </Dialog>
      )}
    </>
  ) : (
    <CharityFormSkeleton />
  )
}
