import React from 'react'
import { THEME } from '../../../lib'

const { supportEmail } = THEME

export const DisabledCharity = () => {
  return (
    <div className="card p-lg alert alert-warning">
      <h1 className="text-xl mb-md">Your organisation has been deactivated</h1>
      <p>
        For any inquiries, please contact us at{' '}
        <a className="link no-underline" href={`mailto:${supportEmail}`}>
          {supportEmail}
        </a>
      </p>
    </div>
  )
}
