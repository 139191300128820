import React, { FunctionComponent } from 'react'
import { benefitToReadableString } from '../../../../components/shared/forms/enumsToReadableStrings'
import { Link } from 'react-router-dom'
import { AlertIcon, EditIcon, VerifiedIcon } from '../../../../components/shared'
import { Image } from '../../../../components/shared/Image'

type Props = {
  charity: Charity | undefined
  hasPendingChanges: boolean
  requestStatus: RequestStatus
}

export const CharityCard: FunctionComponent<Props> = ({ charity, hasPendingChanges, requestStatus }) => {
  if (requestStatus === 'loading' || !charity) {
    return (
      <div className="card p-lg">
        <CharityCardSkeleton />
      </div>
    )
  }

  const { id, logoUrl, organization, status } = charity
  const { benefit, displayName, name } = organization

  return (
    <div className="card p-md relative">
      <div className="flex justify-between mb-sm">
        <div className="text-sm text-storm flex-1">{benefitToReadableString[benefit]}</div>
        <Link to={`/charity/${id}`} className="absolute top-md right-md" aria-label="Edit charity">
          <EditIcon width="1.5rem" height="1.5rem" />
        </Link>
      </div>

      <div className="flex items-center justify-between space-x-md">
        <Image imageUrl={logoUrl as string} altText={`${displayName} logo`} />

        <div className="flex-1">
          <div className="text-md font-bold">{displayName}</div>
          <div className="text-storm">{name}</div>
        </div>

        <div className="place-self-end">
          {status === 'NEW' || hasPendingChanges ? (
            <span className="tag tag-orange inline-block">Pending approval</span>
          ) : status === 'ACTIVE' ? (
            <VerifiedIcon title="Approved" width="1.5rem" height="1.5rem" className="text-green" />
          ) : (
            <AlertIcon title="Disabled" width="1.5rem" height="1.5rem" className="text-red" />
          )}
        </div>
      </div>
    </div>
  )
}

const CharityCardSkeleton = () => (
  <>
    <div className="motion-safe:animate-pulse">
      <div className="flex items-start justify-between">
        <div className="bg-dust w-10 h-[0.5rem] rounded" />
        <div className="bg-dust w-[1.5rem] h-[1.5rem] rounded" />
      </div>

      <div className="flex items-center space-x-md mt-md">
        <div className="relative bg-dust w-8 h-8 rounded" />

        <div className="flex flex-col space-y-sm">
          <div className="bg-dust w-14 h-[0.8rem] rounded" />
          <div className="bg-dust w-10 h-[0.8rem] rounded" />
        </div>
      </div>
    </div>
  </>
)
