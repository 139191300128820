import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { isSignInWithEmailLink, onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from './firebase'
import { logError } from '@tomra/datadog-browser-logging'
import { SmallScreenNavBar } from './components/layout/SmallScrenNavBar'
import { LargeScreenNavBar } from './components/layout/LargeScreenNavBar'

export const AppLayout = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<'toBeDecided' | 'yes' | 'no'>('toBeDecided')
  const navigate = useNavigate()

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user === null) {
        setIsAuthenticated('no')

        if (!isSignInWithEmailLink(auth, window.location.href)) {
          return navigate('/login')
        }
      } else {
        window.DD_RUM && window.DD_RUM.setUser({ id: auth.currentUser?.uid })
        setIsAuthenticated('yes')
      }
    })
  }, [navigate])

  const logout = async () => {
    try {
      await signOut(auth)
    } catch (error: unknown) {
      logError(new Error('Unable to sign out user'), error as Error)
    }
  }

  return (
    <>
      <SmallScreenNavBar isAuthenticated={isAuthenticated} logout={logout} />

      <LargeScreenNavBar isAuthenticated={isAuthenticated} logout={logout} />

      <div className="max-w-screen-2xl mx-auto w-full px-md">
        {isAuthenticated === 'toBeDecided' ? (
          <div className="centerAbsolute">
            <div className="loadingSpinner" />
          </div>
        ) : (
          <Outlet />
        )}
      </div>
    </>
  )
}
