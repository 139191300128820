import React from 'react'

export const CampaignCardSkeleton = () => (
  <>
    <div className="sr-only" aria-busy="true">
      Loading campaign...
    </div>
    <div className="card p-lg" aria-hidden="true">
      <div className="motion-safe:animate-pulse">
        <div className="flex items-center justify-between">
          <div className="flex flex-col space-y-sm">
            <div className="bg-dust w-10 h-[0.5rem] rounded" />
            <div className="bg-dust w-10 h-[1rem] rounded" />
          </div>

          <div className="flex flex-col space-y-sm">
            <div className="bg-dust w-5 h-[0.5rem] rounded" />
            <div className="bg-dust w-3 h-[1rem] rounded" />
          </div>

          <div className="flex flex-col space-y-sm">
            <div className="bg-dust w-5 h-[0.5rem] rounded" />
            <div className="bg-dust w-3 h-[1rem] rounded" />
          </div>

          <div className="bg-dust w-[1.5rem] h-[1.5rem] rounded" />
        </div>
      </div>
    </div>
  </>
)
