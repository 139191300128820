import React, { useEffect } from 'react'
import { StarIcon } from './icons/StarIcon'
import anime from 'animejs/lib/anime.es'

export const AnimatedStars = () => {
  useEffect(() => {
    anime({
      targets: '.star1',
      translateY: [20, -30, -5],
      opacity: [0, 1.0],
      scale: [1.0, 1.5, 1.0],
      fill: ['#ffffff', '#ffcd00'],
      easing: 'easeInOutSine',
      duration: 500,
      delay: 0
    })

    anime({
      targets: '.star2',
      translateY: [20, -30, -15],
      opacity: [0, 1.0],
      scale: [1.0, 1.5, 1.0],
      fill: ['#ffffff', '#ffcd00'],
      easing: 'easeInOutSine',
      duration: 500,
      delay: 100
    })

    anime({
      targets: '.star3',
      translateY: [20, -30, -10],
      opacity: [0, 1.0],
      scale: [1.0, 1.5, 1.0],
      fill: ['#ffffff', '#ffcd00'],
      easing: 'easeInOutSine',
      duration: 500,
      delay: 200
    })
  }, [])

  return (
    <div className="absolute flex items-center space-x-xs -top-md right-0">
      <StarIcon className="star1" size="0.8rem" />
      <StarIcon className="star2" size="1rem" />
      <StarIcon className="star3" size="0.8rem" />
    </div>
  )
}
