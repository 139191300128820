import React from 'react'
import { Label, InfoTooltip, Datepicker, Accordion } from '../'
import { legalStructureToReadableString } from './enumsToReadableStrings'
import { THEME } from '../../../lib'
import { subYears } from 'date-fns'

type Props = {
  organization: Organization
  onOrganizationFieldsChange: (organization: Partial<Organization>) => void
  uiFields: CharityUiFields
  onUiFieldsChange: (uiFields: Partial<CharityUiFields>) => void
}

const { stateShort } = THEME

export const Eligibility = ({ organization, onOrganizationFieldsChange, uiFields, onUiFieldsChange }: Props) => {
  const maxDate = subYears(new Date(), 2)

  const { registeredState, establishedDate, legalStructure, legalStructureDetails } = organization

  return (
    <Accordion title="Eligibility">
      <div>
        <Label htmlFor="registeredState">Which state is your organisation registered at?</Label>

        <select
          id="registeredState"
          className="select w-min"
          value={registeredState}
          onChange={({ target }) =>
            onOrganizationFieldsChange({
              registeredState: target.value
            })
          }
          required
        >
          <option value="" className="hidden">
            Select one &or;
          </option>
          <option value="NSW">NSW</option>
          <option value="NT">NT</option>
          <option value="QLD">QLD</option>
          <option value="VIC">VIC</option>
          <option value="TAS">TAS</option>
          <option value="ACT">ACT</option>
          <option value="WA">WA</option>
          <option value="SA">SA</option>
        </select>
      </div>

      <div>
        <Label
          htmlFor="establishedDate"
          tooltipContent="To be eligible, your organisation must have been operating for 2 years"
        >
          When did your organisation start operating?
        </Label>

        <Datepicker
          id="establishedDate"
          value={new Date(establishedDate)}
          disabled={!registeredState}
          aria-disabled={!registeredState}
          onChange={date =>
            onOrganizationFieldsChange({
              establishedDate: new Date(date).toISOString()
            })
          }
          max={maxDate}
          required
        />
      </div>

      <fieldset className="space-y-md [&>div]:flex [&>div]:align-center [&>div>input]:mr-md [&>hr]:border-[var(--colors-fog)]">
        <legend>What Legal structure best describes your not-for-profit organisation?</legend>

        <div>
          <input
            type="radio"
            className="radio"
            id="legalStructure1"
            value="ACNC_CHARITY"
            name="legalStructure"
            checked={legalStructure === 'ACNC_CHARITY'}
            onChange={(evt: any) => {
              if (evt.target.checked) {
                onOrganizationFieldsChange({ legalStructure: evt.target.value })
              }
            }}
            required
            readOnly
          />
          <label htmlFor="legalStructure1">{legalStructureToReadableString[stateShort]['ACNC_CHARITY']}</label>
        </div>

        <hr />

        <div>
          <input
            type="radio"
            className="radio"
            id="legalStructure2"
            value="REGISTERED_SCHOOL"
            name="legalStructure"
            checked={legalStructure === 'REGISTERED_SCHOOL'}
            onChange={(evt: any) => {
              if (evt.target.checked) {
                onOrganizationFieldsChange({ legalStructure: evt.target.value })
              }
            }}
            required
            readOnly
          />
          <label htmlFor="legalStructure2">{legalStructureToReadableString[stateShort]['REGISTERED_SCHOOL']}</label>
        </div>

        <hr />

        <div>
          <input
            type="radio"
            className="radio"
            id="legalStructure3"
            value="NON_PROFIT_UNDER_AIA2009"
            name="legalStructure"
            checked={legalStructure === 'NON_PROFIT_UNDER_AIA2009'}
            onChange={(evt: any) => {
              if (evt.target.checked) {
                onOrganizationFieldsChange({ legalStructure: evt.target.value })
              }
            }}
            required
            readOnly
          />
          <label htmlFor="legalStructure3">
            {legalStructureToReadableString[stateShort]['NON_PROFIT_UNDER_AIA2009']}
          </label>
          <InfoTooltip content="Note that sporting clubs must be affiliated with a recognised state sporting organisation" />
        </div>

        <hr />

        <div>
          <input
            type="radio"
            className="radio"
            id="legalStructure4"
            value="NON_PROFIT_COMPANY"
            name="legalStructure"
            checked={legalStructure === 'NON_PROFIT_COMPANY'}
            onChange={(evt: any) => {
              if (evt.target.checked) {
                onOrganizationFieldsChange({ legalStructure: evt.target.value })
              }
            }}
            required
            readOnly
          />
          <label htmlFor="legalStructure4">{legalStructureToReadableString[stateShort]['NON_PROFIT_COMPANY']}</label>
          <InfoTooltip content="Note that sporting clubs must be affiliated with a recognised state sporting organisation" />
        </div>

        <hr />

        <div>
          <input
            type="radio"
            className="radio"
            id="legalStructure5"
            value="OTHER"
            name="legalStructure"
            checked={legalStructure === 'OTHER'}
            onChange={(evt: any) => {
              if (evt.target.checked) {
                onOrganizationFieldsChange({ legalStructure: evt.target.value })
              }
            }}
            required
            readOnly
          />
          <label htmlFor="legalStructure5">{legalStructureToReadableString[stateShort]['OTHER']}</label>
        </div>

        {legalStructure !== 'OTHER' && <hr />}
      </fieldset>

      {legalStructure === 'OTHER' && (
        <>
          <div>
            <Label htmlFor="legalStructureDetails">Please specify</Label>

            <input
              type="text"
              className="max-w-30"
              id="legalStructureDetails"
              value={legalStructureDetails}
              onChange={evt => onOrganizationFieldsChange({ legalStructureDetails: evt.target.value })}
              required
            />
          </div>

          <hr />
        </>
      )}

      <div className="flex items-center">
        <input
          id="operatesInState"
          type="checkbox"
          className="checkbox mr-sm"
          checked={uiFields.operatesInState}
          onChange={evt => onUiFieldsChange({ operatesInState: evt.target.checked })}
          required
        />
        <label htmlFor="operatesInState" className="flex-auto select-none">
          By ticking this box you are confirming that you currently operate in {stateShort}
        </label>
      </div>
    </Accordion>
  )
}
