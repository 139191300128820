import { ampli } from '../ampli'
import { AMPLITUDE_CONFIG } from './environment'
import { logError } from '@tomra/datadog-browser-logging'

export function initAmplitude() {
  if (!ampli.client) {
    const disabled = !!window.location.host.match(/(:\d{4})/gi)

    const defaultTracking = disabled
      ? false
      : {
          pageViews: true,
          sessions: true,
          formInteractions: true,
          fileDownloads: false
        }

    try {
      ampli.load({
        disabled,
        client: {
          apiKey: AMPLITUDE_CONFIG.apiKey,
          configuration: {
            defaultTracking
          }
        }
      })
    } catch (error: unknown) {
      logError(new Error('Unable to load Amplitude'), error as Error)
    }
  }
}
