import React, { FunctionComponent, useState } from 'react'
import { currencyFormatter } from '../../lib/campaign'

const noOp = () => {}

type Props = {
  value: number
  onChange: (value: number) => void
}

export const CurrencyInputField: FunctionComponent<Props> = ({ value, onChange }) => {
  const [showNumericField, setShowNumericField] = useState(false)

  return (
    <>
      {showNumericField ? (
        <input
          type="text"
          id="financialTarget"
          value={value}
          min={100}
          max={1000000}
          onChange={({ target }) => onChange(parseInt(target.value))}
          onBlur={() => setShowNumericField(false)}
          required
        />
      ) : (
        <input
          type="text"
          id="financialTarget"
          value={currencyFormatter.format(value)}
          onChange={noOp}
          onFocus={() => setShowNumericField(true)}
          required
        />
      )}
    </>
  )
}
