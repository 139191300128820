import React, { useState } from 'react'
import { CheckmarkIcon, MinusIcon, PlusIcon } from '../shared'
import { ampli } from '../../ampli'

type Props = {
  title: string
  locked?: boolean
  children: React.ReactNode
}

export const Accordion = ({ children, locked, title }: Props) => {
  const [open, setOpen] = useState(false)
  // Generate a randomish number between 0 and 1000 to make accordion header and panel id unique
  const index = Math.floor(Math.random() * 1000)

  return (
    <fieldset
      className={`card flex flex-col relative group ${
        locked ? 'opacity-70 cursor-not-allowed pointer-events-none' : 'opacity-100'
      }`}
    >
      <h2>
        <button
          id={`accordion-header-${index}`}
          aria-expanded={open}
          aria-controls={`accordion-panel-${index}`}
          className="p-lg capitalize w-full"
          onClick={(evt: React.MouseEvent) => {
            evt.preventDefault()
            ampli.toggleCharityFormSection({ state: open ? 'close' : 'open', section: title })
            setOpen(!open)
          }}
        >
          <span className="flex items-center text-md md:text-lg">
            <div className="checkmark-icon mr-md transition text-fog group-valid:text-green">
              <CheckmarkIcon width="2rem" height="2rem" />
            </div>
            <span className="flex-1 text-left">{title}</span>
            <span className={`transition ${open ? 'rotate-180' : 'rotate-0'}`}>
              {open ? <MinusIcon width="2rem" height="2rem" /> : <PlusIcon width="2rem" height="2rem" />}
            </span>
          </span>
        </button>
      </h2>

      <div
        id={`accordion-panel-${index}`}
        aria-labelledby={`accordion-header-${index}`}
        className={`space-y-xl ${open ? 'h-min pb-lg px-lg' : 'h-0 overflow-hidden'}`}
      >
        {children}
      </div>
    </fieldset>
  )
}
