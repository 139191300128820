import React, { FunctionComponent, useState } from 'react'
import { FormValidationError } from '../../../components/shared/FormValidationError'
import { ValidationError, object, string } from 'yup'
import { emailRegex } from '../../../constants'

const INVALID_EMAIL_MESSAGE = 'Please enter a valid email'

const schema = object().shape({
  email: string()
    .email(INVALID_EMAIL_MESSAGE)
    .required()
    .test('custom-email-validation', INVALID_EMAIL_MESSAGE, value => emailRegex.test(value))
})

type Props = {
  buttonLabel: string
  email: string
  setEmail: (email: string) => void
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const LoginForm: FunctionComponent<Props> = ({ buttonLabel, email, setEmail, onSubmit }) => {
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])

  const validateAndSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.target as HTMLFormElement)
    try {
      schema.validateSync(Object.fromEntries(formData.entries()))
      onSubmit(event)
    } catch (error) {
      setValidationErrors([error as ValidationError])
    }
  }

  return (
    <form className="flex flex-col items-center space-y-lg animate-fadeIn" onSubmit={validateAndSubmit}>
      <div className="w-full">
        <label htmlFor="email" className="label">
          E-mail
        </label>
        <input
          id="email"
          name="email"
          type="email"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          required
        />
        <FormValidationError field="email" errors={validationErrors} />
      </div>
      <button type="submit" className="btn btn-primary-dark">
        {buttonLabel}
      </button>
    </form>
  )
}
