import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { EditIcon } from '../../../../components/shared'
import { CampaignCardSkeleton } from './CampaignCardSkeleton'
import { CampaignStart } from './CampaignStart'
import { CampaignEnd } from './CampaignEnd'
import { ExtendCampaign } from './ExtendCampaign'
import { ProgressBar } from '../../../../components/shared/ProgressBar'
import { currencyFormatter } from '../../../../lib/campaign'

const campaignStatusMap: { [key: string]: string } = {
  NEW: 'Current campaign',
  ACTIVE: 'Current campaign',
  EXPIRED: 'Expired campaign',
  STOPPED: 'Stopped campaign'
}

type Props = {
  charity: Charity | undefined
  campaignWithStatistics: CampaignWithStatistics | undefined | null
}

export const CampaignCard: FunctionComponent<Props> = ({ campaignWithStatistics, charity }) => {
  if (!campaignWithStatistics || !charity) {
    return <CampaignCardSkeleton />
  }

  const { campaignId, name, status, financialTarget, startTime, endTime } = campaignWithStatistics.campaign
  const { totalDonated } = campaignWithStatistics.statistics

  return (
    <div className="card p-md relative">
      <div className="absolute top-md right-md">
        {status !== 'EXPIRED' && status !== 'STOPPED' && (
          <Link to={`/charity/${charity.id}/campaign/${campaignId}`} aria-label="Edit campaign">
            <EditIcon width="1.5rem" height="1.5rem" />
          </Link>
        )}
      </div>

      <div className="flex flex-col space-y-md mb-lg md:space-y-0 md:flex-row md:items-start md:justify-between md:pr-xl">
        <div className="md:flex md:flex-col md:justify-between">
          <div className="text-sm text-storm">{campaignStatusMap[status]}</div>
          <div className="text-md font-bold">{name}</div>
        </div>

        <div className="md:flex md:flex-col md:justify-between">
          <CampaignStart status={status} startTime={startTime as string} />
        </div>

        <div className="md:flex md:flex-col md:justify-between">
          <CampaignEnd status={status} endTime={endTime as string} />
        </div>

        {(status === 'EXPIRED' || status === 'STOPPED') && (
          <Link to={`/charity/${charity.id}/campaign?copy=${campaignId}`} className="btn btn-primary-dark">
            Copy
          </Link>
        )}

        <ExtendCampaign charityId={charity.id} campaignId={campaignId} endTime={endTime as string} />
      </div>

      {status !== 'NEW' && <ProgressBar current={totalDonated} total={financialTarget} />}

      <div className="flex space-x-lg items-end justify-between md:justify-end">
        {status === 'NEW' ? (
          <div>
            <div className="text-sm text-midnight">Goal</div>
            <div className="md:flex-1">{currencyFormatter.format(financialTarget)}</div>
          </div>
        ) : (
          <>
            <div className="flex-1">{currencyFormatter.format(totalDonated)}</div>
            {currencyFormatter.format(financialTarget)}
          </>
        )}

        {status === 'NEW' && <div className="tag tag-orange inline-block">Pending approval</div>}
      </div>
    </div>
  )
}
