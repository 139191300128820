import React, { useState, useEffect, FunctionComponent, useMemo, ReactNode } from 'react'
import { Eligibility, Details, Location, Description, Banking, Contact, AlertIcon, TermsAndConditions } from '../shared'
import { THEME } from '../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { intlFormat, parseISO } from 'date-fns'
import { SavingStatus } from '../shared/SavingStatus'
import { useDebouncedCallback } from 'use-debounce'
import { updateDraft } from '../../services/firestore.service'
import { findObjectDifference } from '../../pages/charity/common/findObjectDifference'
import { validateCharityForm } from '../../lib/charity'

const { displayName, supportEmail } = THEME

type Props = {
  adminId: string
  draft: Draft
  originalBankingData: BankingData | null
  onSubmit: (charity: Charity) => void
  submitRequestStatus: RequestStatus
  submitError: string
  children: ReactNode
}

export const CharityForm: FunctionComponent<Props> = ({
  adminId,
  draft,
  originalBankingData,
  onSubmit,
  submitRequestStatus,
  submitError,
  children
}) => {
  const [changes, setChanges] = useState<Draft>(draft)
  const [draftStatus, setDraftStatus] = useState<RequestStatus>('initial')
  const [formValidationErrors, setFormValidationErrors] = useState<{ [key: string]: CharityFormValidationError[] }>({})
  const [showFormValidationErrors, setShowFormValidationErrors] = useState(false)

  const setOrganizationChanges = (organization: Partial<Organization>) =>
    setChanges(prevState => ({
      ...prevState,
      updatedAt: new Date().toISOString(),
      charity: {
        ...prevState.charity,
        organization: { ...prevState.charity.organization, ...organization }
      }
    }))

  const setBankingDataChanges = (bankingData: Partial<BankingData>) =>
    setChanges(prevState => ({
      ...prevState,
      updatedAt: new Date().toISOString(),
      charity: {
        ...prevState.charity,
        bankingData: { ...prevState.charity.bankingData, ...bankingData }
      }
    }))

  const setPrimaryContactChanges = (primaryContact: Partial<PrimaryContact>) => {
    setChanges(prevState => ({
      ...prevState,
      updatedAt: new Date().toISOString(),
      charity: {
        ...prevState.charity,
        primaryContact: { ...prevState.charity.primaryContact, ...primaryContact }
      }
    }))
  }

  const setSecondaryContactChanges = (secondaryContact: Partial<SecondaryContact>) =>
    setChanges(prevState => ({
      ...prevState,
      updatedAt: new Date().toISOString(),
      charity: {
        ...prevState.charity,
        secondaryContact: { ...prevState.charity.secondaryContact, ...secondaryContact }
      }
    }))

  const setUiFieldChanges = (uiFields: Partial<CharityUiFields>) => {
    setChanges(prevState => ({
      ...prevState,
      updatedAt: new Date().toISOString(),
      uiFields: { ...prevState.uiFields, ...uiFields }
    }))
  }

  const diff = useMemo(() => findObjectDifference(draft, changes), [changes])
  const charityHasChanges = Object.keys(diff).length > 0

  const debouncedSave = useDebouncedCallback((values: Draft) => {
    setDraftStatus('loading')

    updateDraft(adminId, values)
      .then(() => {
        setDraftStatus('success')
      })
      .catch(error => {
        logError(new Error('Unable to save draft'), error)
        setDraftStatus('failed')
      })
  }, 1000)

  useEffect(() => {
    if (changes) {
      debouncedSave(changes)
    }
  }, [changes, debouncedSave, charityHasChanges])

  useEffect(() => {
    const errors = validateCharityForm(changes)
    setFormValidationErrors(errors as { [key: string]: CharityFormValidationError[] })

    if (Object.keys(errors).length === 0) {
      setShowFormValidationErrors(false)
    }
  }, [changes])

  return (
    <>
      <form
        className="group"
        onSubmit={e => {
          e.preventDefault()

          const hasErrors = Object.keys(formValidationErrors).length > 0
          setShowFormValidationErrors(hasErrors)

          if (charityHasChanges && !hasErrors) {
            onSubmit(changes.charity)
          }
        }}
        noValidate
      >
        <div className="flex flex-col space-y-md md:max-w-screen-2xl md:mx-auto md:my-md md:grid md:grid-cols-12 md:gap-x-md animate-fadeIn">
          <div className="md:col-span-8 space-y-md">
            <div className="card px-xl py-lg flex flex-col space-y-lg">
              <div className="mb-lg">{children}</div>
            </div>

            {!originalBankingData && (
              <TermsAndConditions uiFields={changes.uiFields} onUiFieldsChange={setUiFieldChanges} />
            )}

            <Eligibility
              organization={changes.charity.organization}
              onOrganizationFieldsChange={setOrganizationChanges}
              uiFields={changes.uiFields}
              onUiFieldsChange={setUiFieldChanges}
            />

            <Details
              charityId={changes.charity.id}
              uiFields={changes.uiFields}
              onUiFieldsChange={setUiFieldChanges}
              organization={changes.charity.organization}
              onOrganizationFieldsChange={setOrganizationChanges}
            />

            <Location organization={changes.charity.organization} onOrganizationFieldsChange={setOrganizationChanges} />

            <Description
              charityId={changes.charity.id}
              uiFields={changes.uiFields}
              onUiFieldsChange={setUiFieldChanges}
              organization={changes.charity.organization}
              onOrganizationFieldsChange={setOrganizationChanges}
              logoUrl={changes.charity.logoUrl as string}
              setLogoUrl={logoUrl => {
                setChanges(prevState => ({
                  ...prevState,
                  charity: { ...prevState.charity, logoUrl }
                }))
              }}
            />

            <Banking
              charityId={changes.charity.id}
              uiFields={changes.uiFields}
              onUiFieldsChange={setUiFieldChanges}
              originalBankingData={originalBankingData}
              bankingData={changes.charity.bankingData}
              onBankingDataFieldsChange={setBankingDataChanges}
            />

            <Contact
              primaryContact={changes.charity.primaryContact}
              onPrimaryContactFieldsChange={setPrimaryContactChanges}
              secondaryContact={changes.charity.secondaryContact}
              onSecondaryContactFieldsChange={setSecondaryContactChanges}
            />
          </div>

          <div className="md:col-span-4">
            <aside className="top-md sticky flex flex-col space-y-lg items-center card p-lg">
              <div className="w-full flex flex-col items-center space-y-sm">
                <SavingStatus status={draftStatus} />

                <div className="text-sm flex items-center space-x-xs">
                  {draftStatus === 'loading' ? (
                    <span>Saving changes...</span>
                  ) : draftStatus === 'failed' ? (
                    <span className="text-center">
                      Unable to save changes.
                      <br />
                      You can still submit the changes.
                    </span>
                  ) : draftStatus === 'success' ? (
                    <>
                      <span>Draft saved</span>
                      <span>
                        {intlFormat(parseISO(changes.updatedAt), {
                          localeMatcher: 'lookup',
                          day: '2-digit',
                          month: '2-digit',
                          year: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </span>
                    </>
                  ) : null}
                </div>
              </div>

              {originalBankingData && charityHasChanges && (
                <div className="font-bold">You have unsubmitted changes</div>
              )}

              <div className="flex items-end space-x-md w-full md:w-auto">
                <button
                  type="submit"
                  className="btn btn-primary-dark w-full md:w-auto aria-disabled:opacity-75 group-invalid:cursor-not-allowed h-3"
                  aria-disabled={submitRequestStatus === 'loading' || !charityHasChanges}
                >
                  {submitRequestStatus === 'loading' ? <div className="loadingSpinner" /> : 'Submit for review'}
                </button>
              </div>

              {submitRequestStatus === 'failed' && (
                <div className="flex items-start space-x-md text-red-dark">
                  <AlertIcon width="2em" />
                  <p className="prose text-red-dark font-bold">
                    Something went wrong. Please try submitting again shortly. If the issue persists, please contact us
                    at
                    <a href={`mailto:${supportEmail}`} className="border-b ml-xs no-underline text-red-dark font-bold">
                      {supportEmail}
                    </a>
                  </p>
                </div>
              )}

              {submitRequestStatus === 'preConditionFailed' && (
                <div className="flex items-start space-x-md text-red-dark">
                  <AlertIcon width="2em" />
                  <p className="prose text-red-dark font-bold">{submitError}</p>
                </div>
              )}

              {showFormValidationErrors && (
                <div>
                  <h2 className="text-red-dark font-bold mb-sm">
                    Please correct the following before submitting the form:
                  </h2>
                  <ul className="flex flex-col space-y-lg">
                    {Object.keys(formValidationErrors).map((key, i) => (
                      <li key={`se-${i}`}>
                        <span className="font-bold">{key}</span>
                        <ul className="flex flex-col space-y-xs pl-md list-disc">
                          {formValidationErrors[key].map((error, i) => (
                            <li key={`em-${i}`}>{error.message}</li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </aside>
          </div>
        </div>
      </form>

      <div className="flex items-center justify-center mx-auto max-w-screen-sm mt-xl">
        <img src={`/${THEME.brandingLogos}`} alt={`${displayName} logos`} className="max-h-4" />
      </div>
    </>
  )
}
