import { addDays, subYears } from 'date-fns'
import { object, string, date, ValidationError, boolean } from 'yup'
import {
  benefitToReadableString,
  dgrStatusToReadableString,
  geoFootprintToReadableString,
  legalStructureToReadableString
} from '../components/shared/forms/enumsToReadableStrings'

export function validateCharityForm(changes: Draft) {
  const validateTos = validateObject(ValidTOS, changes)
  const validateEligibility = validateObject(ValidEligibility, changes)
  const validateDetailsOfYourOrganization = validateObject(ValidDetailsOfYourOrganisation, changes)
  const validateLocationAndOperationalFootprint = validateObject(ValidLocationAndOperationalFootprint, changes)
  const validateAboutYourOrganisation = validateObject(ValidAboutYourOrganisation, changes)
  const validateFinance = validateObject(ValidFinance, changes)
  const validateContact = validateObject(ValidContact, changes)

  return {
    ...(validateTos.length > 0 && { 'Terms And Conditions': validateTos }),
    ...(validateEligibility.length > 0 && { Eligibility: validateEligibility }),
    ...(validateDetailsOfYourOrganization.length > 0 && {
      'Details Of Your Organisation': validateDetailsOfYourOrganization
    }),
    ...(validateLocationAndOperationalFootprint.length > 0 && {
      'Location And Operational Footprint': validateLocationAndOperationalFootprint
    }),
    ...(validateAboutYourOrganisation.length > 0 && {
      'About Your Organisation': validateAboutYourOrganisation
    }),
    ...(validateFinance.length > 0 && {
      Finance: validateFinance
    }),
    ...(validateContact.length > 0 && {
      Contact: validateContact
    })
  }
}

export const ValidTOS = object({
  uiFields_hasAcceptedTos: boolean().when('uiFields_organizationLogoFileName', ([organizationLogoFileName], schema) => {
    return !organizationLogoFileName
      ? schema.required().oneOf([true], 'You must accept the terms and conditions')
      : schema
  })
})

export const ValidEligibility = object({
  charity_organization_registeredState: string()
    .oneOf(['NSW', 'VIC', 'QLD', 'SA', 'WA', 'TAS', 'NT', 'ACT'], 'Registered in must be selected')
    .required('Registered in must be selected'),
  charity_organization_legalStructure: string()
    .oneOf(
      [...Object.keys(legalStructureToReadableString.NSW), ...Object.keys(legalStructureToReadableString.VIC)],
      'Legal structure must be selected'
    )
    .required('Legal structure must be selected'),
  charity_organization_legalStructureDetails: string().when(
    'charity_organization_legalStructure',
    ([legalStructure], schema) => {
      return legalStructure === 'OTHER' ? schema.required() : schema
    }
  ),
  charity_organization_establishedDate: date()
    .max(addDays(subYears(new Date(), 2), 1), 'Established date must be at earlier than 2 years ago')
    .required(),
  uiFields_operatesInState: boolean()
    .oneOf([true], 'You must confirm that you currently operate in this state')
    .required()
})

export const ValidDetailsOfYourOrganisation = object({
  charity_organization_name: string().required(),
  charity_organization_incorporationNumber: string().when(
    'charity_organization_legalStructure',
    ([legalStructure], schema) => {
      return legalStructure === 'NON_PROFIT_UNDER_AIA2009'
        ? schema.required('Incorporation number is a required field for your selected legal structure')
        : schema
    }
  ),
  charity_organization_localGroupName: string().when('uiFields_abnBelongsToParent', ([abnBelongsToParent], schema) => {
    return abnBelongsToParent
      ? schema.required('Local group name is a required field if the ABN belongs to your parent organisation')
      : schema
  }),
  uiFields_incorporationFileName: string().when('charity_organization_legalStructure', ([legalStructure], schema) => {
    return legalStructure === 'NON_PROFIT_UNDER_AIA2009'
      ? schema.required('Incorporation file must be uploaded for your selected legal structure')
      : schema
  }),
  uiFields_abnBelongsToParent: boolean().optional(),
  uiFields_abnFileName: string().required('A copy of your ABN certificate must be uploaded'),
  uiFields_parentOrganizationLetterFileName: string().when(
    'uiFields_abnBelongsToParent',
    ([abnBelongsToParent], schema) => {
      return abnBelongsToParent
        ? schema.required(
            'A parent organisation letter must be uploaded if the ABN belongs to your parent organisation'
          )
        : schema
    }
  )
})

export const ValidLocationAndOperationalFootprint = object({
  charity_organization_geoFootprint: string()
    .oneOf(Object.keys(geoFootprintToReadableString), 'Geographical footprint must be selected')
    .required('Geographical footprint must be selected'),
  charity_organization_streetAddress: string().max(35).required(),
  charity_organization_suburb: string().optional(),
  charity_organization_postCode: string().required()
})

export const ValidAboutYourOrganisation = object({
  charity_organization_displayName: string().required(),
  charity_organization_websiteUrl: string().required(),
  charity_organization_benefit: string()
    .oneOf(Object.keys(benefitToReadableString), 'Main community benefit must be selected')
    .required('Main community benefit must be selected'),
  charity_organization_description: string().max(500).required(),
  uiFields_organizationLogoFileName: string().required('A logo must be uploaded')
})

export const ValidFinance = object({
  charity_bankingData_accountName: string().required(),
  charity_bankingData_accountBsb: string().required(),
  charity_bankingData_accountNumber: string().required(),
  charity_bankingData_dgrStatus: string()
    .oneOf(Object.keys(dgrStatusToReadableString), 'Deductible Gift Registration (DGR) status must be selected')
    .required('Deductible Gift Registration (DGR) status must be selected')
})

export const ValidContact = object({
  charity_primaryContact_firstName: string().required(),
  charity_primaryContact_lastName: string().required(),
  charity_primaryContact_position: string().required(),
  charity_primaryContact_email: string().email().required(),
  charity_primaryContact_phone: string().required(),
  charity_secondaryContact_firstName: string().nullable(),
  charity_secondaryContact_lastName: string().nullable(),
  charity_secondaryContact_position: string().nullable(),
  charity_secondaryContact_email: string().email().nullable(),
  charity_secondaryContact_phone: string().nullable()
})

export const pathMap: { [key: string]: string } = {
  charity_organization_legalStructure: 'Legal structure',
  charity_organization_legalStructureDetails: 'Legal structure details',
  charity_organization_establishedDate: 'Established date',
  charity_organization_name: 'Organisation name',
  charity_organization_geoFootprint: 'Geographical footprint',
  charity_organization_streetAddress: 'Street address',
  charity_organization_suburb: 'Suburb',
  charity_organization_postCode: 'Postcode',
  charity_organization_displayName: 'Display name',
  charity_organization_websiteUrl: 'Website URL',
  charity_organization_description: 'Description',
  charity_bankingData_accountName: 'Account name',
  charity_bankingData_accountBsb: 'BSB',
  charity_bankingData_accountNumber: 'Account number',
  charity_bankingData_dgrStatus: 'DGR status',
  charity_primaryContact_firstName: 'First name',
  charity_primaryContact_lastName: 'Last name',
  charity_primaryContact_position: 'Position',
  charity_primaryContact_email: 'Email',
  charity_primaryContact_phone: 'Phone',
  charity_secondaryContact_firstName: 'Secondary first name',
  charity_secondaryContact_lastName: 'Secondary last name',
  charity_secondaryContact_position: 'Secondary position',
  charity_secondaryContact_email: 'Secondary email',
  charity_secondaryContact_phone: 'Secondary phone'
}

export function validateObject<T>(schema: any, object: T) {
  try {
    schema.validateSync(flattenObject(object), { abortEarly: false })
    return []
  } catch (error) {
    if (!error) {
      return []
    }

    return (error as ValidationError).inner.map(error => {
      const { path, type, message } = error

      const mappedPath = pathMap[path as string] || path

      return {
        path,
        type,
        message: message.replace(path as string, mappedPath as string)
      }
    })
  }
}

const attachmentUploadErrorMessage: { [key: string]: string } = {
  IncorporationFile: "Incorporation file under 'Details of your organisation'",
  ABNDocument: "Copy your ABN certificate under 'Details of your organisation'",
  ParentOrganizationLetter:
    "Letter/statutory declaration from parent organisation under 'Details of your organisation'",
  ProofOfBankDetails: "Proof of bank details under 'Finance'"
}

export function parseAttachmentErrors(errorMessage: string) {
  const attachments = Object.keys(attachmentUiFieldMap)

  const missing = attachments.reduce((acc, attachment) => {
    if (errorMessage.includes(attachment)) {
      return [...acc, attachment]
    }
    return acc
  }, [] as string[])

  return (
    "There's an issue with some of the attachments. Please re-upload: " +
    missing.map(attachment => attachmentUploadErrorMessage[attachment]).join(', ')
  )
}

export const attachmentUiFieldMap: { [key: string]: string } = {
  IncorporationFile: 'incorporationFileName',
  ABNDocument: 'abnFileName',
  ParentOrganizationLetter: 'parentOrganizationLetterFileName',
  ProofOfBankDetails: 'proofOfBankDetailsFileName',
  OrganizationLogo: 'organizationLogoFileName'
}

export function flattenObject(object: any, parentKey = '', result: { [key: string]: any } = {}) {
  for (const key in object) {
    const newKey = `${parentKey}${parentKey ? '_' : ''}${key}`

    if (
      typeof object[key] === 'object' &&
      object[key] !== null &&
      !Array.isArray(object[key]) &&
      !(object[key] instanceof Date)
    ) {
      flattenObject(object[key], newKey, result)
    } else {
      result[newKey] = object[key]
    }
  }

  return result
}
