export function findObjectDifference(originalObject: any, changedObject: any) {
  return Object.keys(originalObject).reduce((acc, key) => {
    const original = originalObject[key]
    const changes = changedObject[key]

    if (key === 'updatedAt' || key === 'createdAt' || key === 'logoUrl') {
      return acc
    }

    if (isObject(original) && isObject(changes)) {
      const diff = findObjectDifference(original, changes)

      if (Object.keys(diff).length > 0) {
        acc[key] = diff
      }
    } else if (original !== changes) {
      acc[key] = changes
    }

    return acc
  }, {} as any)
}

export const isObject = (value: any) => typeof value === 'object' && value !== null && value !== undefined
