import React, { FunctionComponent, useEffect } from 'react'
import * as d3 from 'd3'
import { currencyFormatter } from '../../../../lib/campaign'

export const CampaignsRaisedChart: FunctionComponent<{ campaignsWithStatistics: CampaignWithStatistics[] }> = ({
  campaignsWithStatistics
}) => {
  useEffect(() => {
    const container = d3.select('#chart')
    const totalDurationMax = d3.max(campaignsWithStatistics, ({ campaign }) =>
      d3.timeDay.count(new Date(campaign.startTime), new Date(campaign.endTime))
    )

    const totalDonatedScale = d3.scaleLinear().range([0, 600]).domain([0, 10000])
    const totalDurationScale = d3
      .scaleLinear()
      .range([0, 600])
      .domain([0, totalDurationMax as number])

    const join = container.selectAll('div').data(campaignsWithStatistics)

    const campaign = join.enter().append('div').attr('class', 'py-md')

    campaign
      .append('h3')
      .text(({ campaign }) => campaign.name)
      .attr('class', 'text-storm')

    campaign
      .append('div')
      .text(({ statistics }) => `AUD ${currencyFormatter.format(statistics.totalDonated)}`)
      .attr('class', 'bg-[rgba(167,220,205,1)] text-right text-sm px-sm py-xs rounded-r my-sm')
      .style('width', ({ statistics }) => `${totalDonatedScale(statistics.totalDonated)}px`)
      .style('white-space', 'nowrap')

    campaign
      .append('div')
      .text(({ campaign }) => d3.timeDay.count(new Date(campaign.startTime), new Date(campaign.endTime)) + ' days')
      .attr('class', 'bg-[rgba(249,210,219,1)] text-right text-sm px-sm py-xs rounded-r my-sm')
      .style(
        'width',
        ({ campaign }) =>
          `${totalDurationScale(d3.timeDay.count(new Date(campaign.startTime), new Date(campaign.endTime)))}px`
      )

    return () => {
      join.exit().remove()
    }
  }, [])

  return (
    <div>
      <div className="flex items-end justify-between">
        <h2 className="text-md text-storm">Past fundraising campaigns</h2>

        <div className="flex space-x-lg">
          <div className="flex items-center space-x-sm">
            <div className="w-2 h-2 rounded bg-[rgba(249,210,219,1)]" />
            <div className="text-storm">Length of campaign</div>
          </div>
          <div className="flex items-center space-x-sm">
            <div className="w-2 h-2 rounded bg-[rgba(167,220,205,1)]" />
            <div className="text-storm">Money raised</div>
          </div>
        </div>
      </div>

      <div id="chart" />
    </div>
  )
}
