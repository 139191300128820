import React from 'react'
import { AlertIcon } from './index'

export const ErrorCard = () => (
  <div className="card p-lg my-lg max-w-screen-sm mx-auto flex flex-col items-center space-y-md">
    <AlertIcon width="3rem" height="3rem" />

    <h1 className="text-md">Something went wrong on our side</h1>

    <p>
      We are sorry for the inconvenience.
      <br />
      Please{' '}
      <a href="/" className="link">
        click here to reload the page
      </a>
    </p>
  </div>
)
