import React, { FunctionComponent, useEffect, useState } from 'react'
import { ValidationError } from 'yup'

type Props = {
  field: string
  errors: ValidationError[]
}

export const FormValidationError: FunctionComponent<Props> = ({ field, errors }) => {
  const [fieldErrors, setFieldErrors] = useState<string[]>([])

  useEffect(() => {
    const id = setTimeout(() => {
      setFieldErrors(errors.filter(error => error?.path === field).map(error => error.message))
    }, 500)

    return () => clearTimeout(id)
  }, [errors])

  return fieldErrors ? (
    <div className="text-red-dark font-bold mt-xs">
      {fieldErrors.map((fieldError, i) => (
        <span key={`err-${i}`}>{fieldError}</span>
      ))}
    </div>
  ) : null
}
