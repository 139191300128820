import React, { SyntheticEvent, useState } from 'react'
import { updateProfile } from 'firebase/auth'
import type { User } from 'firebase/auth'
import { auth } from '../../firebase'
import { logError } from '@tomra/datadog-browser-logging'
import { useNavigate } from 'react-router-dom'

export const ProfilePage = () => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [displayName, setDisplayName] = useState(auth.currentUser?.displayName || '')
  const navigate = useNavigate()

  const updateDisplayName = async (event: SyntheticEvent) => {
    event.preventDefault()

    try {
      setStatus('pending')
      await updateProfile(auth.currentUser as User, { displayName })
      setStatus('idle')
      navigate('/')
    } catch (error: unknown) {
      setStatus('failure')
      logError(new Error('Unable to update display name'), error as Error)
    }
  }

  return (
    <form
      onSubmit={updateDisplayName}
      className="my-lg card p-lg flex flex-col space-y-lg items-start max-w-screen-sm mx-auto"
    >
      <h1 className="text-xl">{auth.currentUser?.displayName ? 'Your profile' : 'Please complete your profile'}</h1>

      <div className="w-full">
        <label htmlFor="email" className="label">
          E-mail
        </label>
        <input
          id="email"
          type="text"
          value={auth.currentUser?.email as string}
          className="cursor-not-allowed"
          disabled
        />
      </div>

      <div className="w-full">
        <label htmlFor="displayName" className="label">
          Your name
        </label>
        <input
          id="displayName"
          type="text"
          value={displayName}
          onChange={({ target }) => setDisplayName(target.value)}
        />
      </div>

      {status === 'pending' ? (
        <div className="loadingSpinner" />
      ) : (
        <button
          type="submit"
          className="btn btn-primary-dark self-end disabled:cursor-not-allowed"
          disabled={!displayName}
        >
          Save
        </button>
      )}
    </form>
  )
}
