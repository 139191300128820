import React, { FunctionComponent } from 'react'
import { currencyFormatter } from '../../../../lib/campaign'

export const CampaignsTotalRaised: FunctionComponent<{ campaignsWithStatistics: CampaignWithStatistics[] }> = ({
  campaignsWithStatistics
}) => {
  const totalRaised = campaignsWithStatistics.reduce((acc, { statistics }) => acc + statistics.totalDonated, 0)

  return (
    <div className="card overflow-hidden">
      <div className="text-sm font-bold p-lg">Donations</div>
      <div className="flex items-end justify-center space-x-md pb-lg">
        <div className="text-storm">AUD</div>
        <div className="font-bold text-[3rem] text-storm -mb-[0.6rem]">{currencyFormatter.format(totalRaised)}</div>
      </div>
      <div className="p-lg bg-gradient-to-br from-[rgba(255,228,120,0.42)] to-[rgba(248,163,188,0.42)] text-center text-midnight">
        {totalRaised > 0 ? 'has been raised from the start!' : ''}
      </div>
    </div>
  )
}
