import { API_HOST, authenticatedFetchData } from '../lib'

export async function submitCampaign(charityId: string, campaign: Campaign) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/charity/${charityId}/campaign`, {
    method: 'POST',
    body: JSON.stringify({
      ...campaign,
      charityId,
      financialTarget: String(campaign.financialTarget).replace(/\D/g, ''),
      startTime: new Date(campaign.startTime).toISOString(),
      endTime: new Date(campaign.endTime).toISOString()
    })
  }).run()
}

export async function updateCampaign(charityId: string, campaign: Campaign) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/charity/${charityId}/campaign/${campaign.campaignId}`, {
    method: 'PATCH',
    body: JSON.stringify({
      ...campaign,
      financialTarget: String(campaign.financialTarget).replace(/\D/g, ''),
      startTime: new Date(campaign.startTime).toISOString(),
      endTime: new Date(campaign.endTime).toISOString()
    })
  }).run()
}

export async function fetchCampaigns(charityId: string) {
  const pendingCampaigns = (): Promise<Campaign[]> =>
    authenticatedFetchData(`${API_HOST}/charities/v1/pending-campaigns`).run()
  const fetchCampaigns = (): Promise<CampaignWithStatistics[]> =>
    authenticatedFetchData(`${API_HOST}/charities/v1/charity/${charityId}/campaign`).run()

  return Promise.allSettled([fetchCampaigns(), pendingCampaigns()])
    .then(([campaigns, pendingChanges]: any) => ({ campaigns: campaigns.value, pendingChanges: pendingChanges.value }))
    .then(({ campaigns, pendingChanges }) => {
      return campaigns.reduce((acc: CampaignWithChangesIndicator[], value: CampaignWithStatistics) => {
        const hasChanges = pendingChanges.some((ch: Campaign) => ch.campaignId === value.campaign.campaignId)
        return [...acc, { ...value, campaign: { ...value.campaign, hasPendingChanges: hasChanges } }]
      }, [])
    })
}

export async function fetchCampaign(charityId: string, campaignId: string): Promise<Campaign> {
  const fetchPendingChanges = (): Promise<Campaign> =>
    authenticatedFetchData(`${API_HOST}/charities/v1/charity/${charityId}/pending-campaigns/${campaignId}`).run()

  const fetchCampaign = (): Promise<CampaignWithStatistics> =>
    authenticatedFetchData(`${API_HOST}/charities/v1/charity/${charityId}/campaign/${campaignId}`).run()

  return fetchPendingChanges().catch(error => {
    if (error.status === 404) {
      return fetchCampaign().then(campaignWithStatistics => campaignWithStatistics.campaign)
    }

    throw error
  })
}

export async function extendCharityCampaign(charityId: string, campaignId: string, newEndDate: Date) {
  return authenticatedFetchData(
    `${API_HOST}/charities/v1/charity/${charityId}/campaign/${campaignId}/extend/${encodeURIComponent(
      newEndDate.toISOString()
    )}`,
    {
      method: 'PUT'
    }
  ).run()
}
