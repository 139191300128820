import { doc, getDoc, setDoc, Timestamp, deleteDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { charityInit, uiFieldsInit } from '../initialData'
import { THEME } from '../lib'
import { attachmentUiFieldMap } from '../lib/charity'

const { name } = THEME

export function updateDraft(adminId: string, changes: Draft) {
  return setDoc(doc(db, name, adminId), changes, { merge: true })
}

export async function getOrCreateDraft(
  adminId: string,
  charity: Charity | CharityWithAttachments | null
): Promise<Draft> {
  const draftDoc = doc(db, name, adminId)
  const snapshot = await getDoc(draftDoc)

  if (snapshot.exists()) {
    const data = snapshot.data()

    // Previously we didn't know if a draft had attachments, so we didn't save them to the draft.
    // The following block checks if the draft is an old one, and updates the ui fields if it has attachments.
    if (
      Object.hasOwn(data.uiFields, 'localGroupNameFileName') &&
      (charity as CharityWithAttachments)?.attachments.length > 0
    ) {
      const newUIFields = setUiFieldsFromAttachments(charity as CharityWithAttachments)
      await setDoc(draftDoc, { ...data, uiFields: newUIFields })
    }

    // We have some snapshots saved with establishedDate as a Firebase Timestamp instead of a string.
    // The following block turns the Timestamp into a string to prevent Date Range error.
    if (data.charity.organization.establishedDate instanceof Timestamp) {
      const dateString = snapshot.data().charity.organization.establishedDate.toDate().toISOString()
      await setDoc(draftDoc, { charity: { organization: { establishedDate: dateString } } }, { merge: true })
    }

    const updatedSnapshot = await getDoc(draftDoc)
    return updatedSnapshot.data() as Draft
  }

  const draft: Draft = charity
    ? {
        adminId,
        charity,
        uiFields: setUiFieldsFromAttachments(charity as CharityWithAttachments),
        createdAt: charity.createdAt as string,
        updatedAt: charity.updatedAt as string
      }
    : {
        adminId,
        charity: charityInit,
        uiFields: uiFieldsInit,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      }

  await setDoc(draftDoc, draft)
  const draftSnapshot = await getDoc(draftDoc)
  return draftSnapshot.data() as Draft
}

export function deleteDraft(adminId: string) {
  return deleteDoc(doc(db, name, adminId))
}

function setUiFieldsFromAttachments(charity: CharityWithAttachments) {
  return charity.attachments.reduce((acc, attachment) => {
    return {
      ...acc,
      hasAcceptedTos: true,
      operatesInState: true,
      abnBelongsToParent: !!charity?.organization.localGroupName,
      organizationLogoFileName: charity.logoUrl,
      [attachmentUiFieldMap[attachment.type]]: attachment.type
    } as CharityUiFields
  }, uiFieldsInit)
}
