import React from 'react'

export const CharityFormSkeleton = () => {
  return (
    <div className="max-w-screen-2xl mx-auto my-md md:grid md:grid-cols-12 md:gap-x-md">
      <div className="col-span-8 space-y-md">
        <div className="card p-lg h-[12.5625rem]">
          <div className="motion-safe:animate-pulse">
            <div className="flex flex-col items-start space-y-md">
              <div className="bg-dust w-2/3 h-[2rem] rounded" />
              <div className="bg-dust w-1/2 h-[0.5rem] rounded" />
              <div className="bg-dust w-10 h-[0.5rem] rounded" />
            </div>
          </div>
        </div>

        <div className="card p-lg">
          <div className="motion-safe:animate-pulse">
            <div className="flex items-center justify-between space-x-md">
              <div className="bg-dust w-2 h-2 rounded" />
              <div className="flex-1">
                <div className="bg-dust w-2/4 h-[1.5rem] rounded" />
              </div>
              <div className="bg-dust w-2 h-2 rounded" />
            </div>
          </div>
        </div>

        <div className="card p-lg">
          <div className="motion-safe:animate-pulse">
            <div className="flex items-center justify-between space-x-md">
              <div className="bg-dust w-2 h-2 rounded" />
              <div className="flex-1">
                <div className="bg-dust w-2/4 h-[1.5rem] rounded" />
              </div>
              <div className="bg-dust w-2 h-2 rounded" />
            </div>
          </div>
        </div>

        <div className="card p-lg">
          <div className="motion-safe:animate-pulse">
            <div className="flex items-center justify-between space-x-md">
              <div className="bg-dust w-2 h-2 rounded" />
              <div className="flex-1">
                <div className="bg-dust w-2/4 h-[1.5rem] rounded" />
              </div>
              <div className="bg-dust w-2 h-2 rounded" />
            </div>
          </div>
        </div>

        <div className="card p-lg">
          <div className="motion-safe:animate-pulse">
            <div className="flex items-center justify-between space-x-md">
              <div className="bg-dust w-2 h-2 rounded" />
              <div className="flex-1">
                <div className="bg-dust w-2/4 h-[1.5rem] rounded" />
              </div>
              <div className="bg-dust w-2 h-2 rounded" />
            </div>
          </div>
        </div>

        <div className="card p-lg">
          <div className="motion-safe:animate-pulse">
            <div className="flex items-center justify-between space-x-md">
              <div className="bg-dust w-2 h-2 rounded" />
              <div className="flex-1">
                <div className="bg-dust w-2/4 h-[1.5rem] rounded" />
              </div>
              <div className="bg-dust w-2 h-2 rounded" />
            </div>
          </div>
        </div>

        <div className="card p-lg">
          <div className="motion-safe:animate-pulse">
            <div className="flex items-center justify-between space-x-md">
              <div className="bg-dust w-2 h-2 rounded" />
              <div className="flex-1">
                <div className="bg-dust w-2/4 h-[1.5rem] rounded" />
              </div>
              <div className="bg-dust w-2 h-2 rounded" />
            </div>
          </div>
        </div>
      </div>

      <div className="hidden md:col-span-4 md:flex md:flex-col md:space-y-md">
        <div className="card p-lg h-[12.5625rem]">
          <div className="motion-safe:animate-pulse">
            <div className="flex items-center justify-center space-x-md">
              <div className="bg-dust w-1/3 h-[8rem] rounded" />
              <div className="bg-dust w-2/3 h-[8rem] rounded" />
            </div>
          </div>
        </div>

        <div className="card p-lg">
          <div className="motion-safe:animate-pulse">
            <div className="flex flex-col items-center space-y-md">
              <div className="bg-dust w-2 h-2 rounded" />
              <div className="bg-dust w-10 h-[0.5rem] rounded" />
              <div className="bg-dust w-2/3 h-[2rem] rounded" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
