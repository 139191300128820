import React, { useEffect, useState } from 'react'
import { fetchCampaigns, fetchExistingCharity, fetchPendingChangesForCharity } from '../../services'
import { CharityCard } from './common/charity/CharityCard'
import { CampaignCard } from './common/campaign/CampaignCard'
import { useNavigate } from 'react-router-dom'
import { logError } from '@tomra/datadog-browser-logging'
import { CharityState } from './common/CharityState'
import { ErrorCard } from '../../components/shared/ErrorCard'
import { CampaignsTotalRaised } from './common/campaign/CampaignsTotalRaised'
import { CampaignsRaisedChart } from './common/campaign/CampaignsRaisedChart'
import { DisabledCharity } from './common/DisabledCharity'

export const DashboardPage = () => {
  const [charity, setCharity] = useState<Charity>()
  const [campaignsWithStatistics, setCampaignsWithStatistics] = useState<CampaignWithStatistics[]>()
  const [charityRequestStatus, setCharityRequestStatus] = useState<RequestStatus>('loading')
  const [campaignRequestStatus, setCampaignRequestStatus] = useState<RequestStatus>('loading')
  const [pendingChanges, setPendingChanges] = useState<Charity | object>({})

  const navigate = useNavigate()

  useEffect(() => {
    setCharityRequestStatus('loading')

    fetchExistingCharity()
      .then(ch => {
        if (!ch && window.location.pathname !== '/profile') {
          navigate('/charity')
        } else {
          setCharity(ch as Charity)

          return fetchPendingChangesForCharity((ch as Charity).id).then(pendingChanges => {
            setPendingChanges(pendingChanges)
            setCharityRequestStatus('initial')
          })
        }
      })
      .catch(error => {
        logError(new Error('Failed to load existing charities'), error)
        setCharityRequestStatus('failed')
        setCampaignRequestStatus('failed')
      })
  }, [navigate])

  useEffect(() => {
    if (charity) {
      setCampaignRequestStatus('loading')

      fetchCampaigns(charity.id)
        .then((campaigns: CampaignWithStatistics[]) => {
          setCampaignsWithStatistics(campaigns)
          setCampaignRequestStatus('initial')
        })
        .catch(error => {
          logError(new Error('Failed to load campaign', error))
          setCampaignRequestStatus('failed')
        })
    }
  }, [charity])

  if (charityRequestStatus === 'failed' || campaignRequestStatus === 'failed') {
    return <ErrorCard />
  }

  const latestCampaign = campaignsWithStatistics?.sort(({ campaign: campaignA }, { campaign: campaignB }) =>
    campaignA.createdAt < campaignB.createdAt ? 1 : -1
  )?.[0]

  return (
    <>
      <h1 className="sr-only">Charity Dashboard</h1>

      {charity?.status === 'DISABLED' ? (
        <DisabledCharity />
      ) : (
        <div className="flex flex-col space-y-md md:py-lg md:grid md:grid-cols-12 md:items-start md:gap-md md:space-y-0">
          <div className="md:col-span-8 md:row-span-1">
            <CampaignCard charity={charity} campaignWithStatistics={latestCampaign} />
          </div>

          <div className="md:col-start-9 md:col-span-4 md:row-span-2">
            <CharityCard
              charity={charity}
              hasPendingChanges={Object.keys(pendingChanges).length > 0}
              requestStatus={charityRequestStatus}
            />
          </div>

          {campaignsWithStatistics && campaignsWithStatistics.length > 1 ? (
            <>
              <h2 className="md:col-span-12 text-md ml-lg mb-md">Your totals</h2>

              <div className="md:col-span-3">
                <CampaignsTotalRaised campaignsWithStatistics={campaignsWithStatistics} />
              </div>

              <div className="md:col-span-5">
                <CampaignsRaisedChart
                  campaignsWithStatistics={campaignsWithStatistics.filter(
                    ({ campaign }) => campaign.status !== 'NEW' && campaign.status !== 'ACTIVE'
                  )}
                />
              </div>
            </>
          ) : (
            charity && (
              <CharityState
                charityId={charity.id}
                charityStatus={charity.status}
                latestCampaignStatus={latestCampaign?.campaign?.status}
              />
            )
          )}
        </div>
      )}
    </>
  )
}
