import React, { useState } from 'react'
import { FileUpload, Label, Accordion } from '../'
import { logError } from '@tomra/datadog-browser-logging'
import { THEME } from '../../../lib'
import { uploadFile } from '../../../services'
import { useLocation } from 'react-router-dom'

type Props = {
  charityId: string
  uiFields: CharityUiFields
  onUiFieldsChange: (uiFields: Partial<CharityUiFields>) => void
  bankingData: BankingData
  onBankingDataFieldsChange: (bankingData: Partial<BankingData>) => void
  originalBankingData: BankingData | null
}

const { displayName } = THEME

export const Banking = ({
  charityId,
  uiFields,
  onUiFieldsChange,
  bankingData,
  onBankingDataFieldsChange,
  originalBankingData
}: Props) => {
  const [bankDetailsRequestStatus, setBankDetailsRequestStatus] = useState<RequestStatus>('initial')
  const { pathname } = useLocation()

  const hasRestrictedDgr =
    bankingData.dgrStatus === 'RESTRICTED' ||
    bankingData.dgrStatus === 'RESTRICTED_DGR_ACTIVITIES_YES' ||
    bankingData.dgrStatus === 'RESTRICTED_DGR_ACTIVITIES_NO'

  const accountNameHasChanged = originalBankingData && bankingData.accountName !== originalBankingData?.accountName
  const bsbHasChanged = originalBankingData && bankingData.accountBsb !== originalBankingData?.accountBsb
  const bankAccountNumberHasChanged =
    originalBankingData && bankingData.accountNumber !== originalBankingData?.accountNumber

  const bankDetailsHasChanged = bankAccountNumberHasChanged || bsbHasChanged || accountNameHasChanged

  const onBankDetailsUpload = async (proofOfBankDetailsFile: File) => {
    setBankDetailsRequestStatus('loading')

    try {
      await uploadFile(proofOfBankDetailsFile, charityId, 'ProofOfBankDetails')
      setBankDetailsRequestStatus('initial')
      onUiFieldsChange({ proofOfBankDetailsFileName: proofOfBankDetailsFile.name })
    } catch (error: any) {
      setBankDetailsRequestStatus('failed')
      logError(new Error('Failed to upload ProofOfBankDetails file'), error)
    }
  }

  return (
    <Accordion title="Finance" locked={bankDetailsRequestStatus === 'loading'}>
      <div>
        <p>We do not share any of your finance details with a third party.</p>
        <p>Your bank details are secured and only used to make payment of the donations.</p>
      </div>

      <div>
        <Label htmlFor="accountName">Account name</Label>

        <input
          type="text"
          id="accountName"
          value={bankingData.accountName}
          onChange={evt => onBankingDataFieldsChange({ accountName: evt.target.value })}
          required
        />
      </div>

      <div>
        <Label htmlFor="accountBsb">BSB</Label>

        <input
          type="text"
          id="accountBsb"
          value={bankingData.accountBsb}
          onChange={evt => onBankingDataFieldsChange({ accountBsb: evt.target.value.replace(/\s/g, '') })}
          pattern="^\d{6}$"
          title="6 digits"
          required
        />
      </div>

      <div>
        <Label htmlFor="accountNumber">Account number</Label>

        <input
          type="text"
          id="accountNumber"
          value={bankingData.accountNumber}
          onChange={evt => onBankingDataFieldsChange({ accountNumber: evt.target.value.replace(/\s/g, '') })}
          pattern="^\d{1,9}$"
          title="Up to 9 digits"
          required
        />
      </div>

      {bankDetailsHasChanged && (
        <FileUpload
          id="bankDetailsFile"
          uploadedFileName={uiFields.proofOfBankDetailsFileName}
          onChange={onBankDetailsUpload}
          requestStatus={bankDetailsRequestStatus}
          acceptedFileTypes="image/png, image/jpeg, image/jpg, application/pdf"
        >
          <p className="mt-md mb-sm">Please provide us with an updated proof of bank details.</p>
        </FileUpload>
      )}

      {pathname === '/charity' && (
        <FileUpload
          id="bankDetailsFile"
          uploadedFileName={uiFields.proofOfBankDetailsFileName}
          onChange={onBankDetailsUpload}
          requestStatus={bankDetailsRequestStatus}
          acceptedFileTypes="image/png, image/jpeg, image/jpg, application/pdf"
        >
          <div>
            <p>Please provide 1 proof of bank details in 1 of the following 3 formats:</p>

            <ul className="list-disc list-inside my-lg">
              <li>Pre-printed bank deposit slip</li>
              <li>Bank statement header</li>
              <li>Bank information in PDF format on company letterhead signed by management</li>
            </ul>
          </div>
        </FileUpload>
      )}

      <fieldset
        id="dgrStatus"
        className="space-y-md [&>div]:flex [&>div]:align-center [&>div>input]:mr-md [&>hr]:border-[var(--colors-fog)]"
      >
        <Label tooltipContent="If unsure about you DGR endorsement, please check with your tax advisor or contact the ATO.">
          Which of the following statements applies to your Deductible Gift Registration (DGR) status with the
          Australian Tax Office (ATO)?
        </Label>

        <div>
          <input
            type="radio"
            className="radio"
            id="dgrStatus1"
            value="NONE"
            name="dgrStatus"
            checked={bankingData.dgrStatus === 'NONE'}
            onChange={(evt: any) => {
              if (evt.target.value) {
                onBankingDataFieldsChange({ dgrStatus: evt.target.value })
              }
            }}
            required
          />
          <label htmlFor="dgrStatus1">My organisation doesn&apos;t have the DGR endorsement</label>
        </div>

        <hr />

        <div>
          <input
            type="radio"
            className="radio"
            id="dgrStatus2"
            value="UNRESTRICTED"
            name="dgrStatus"
            checked={bankingData.dgrStatus === 'UNRESTRICTED'}
            onChange={(evt: any) => {
              if (evt.target.value) {
                onBankingDataFieldsChange({ dgrStatus: evt.target.value })
              }
            }}
            required
          />
          <label htmlFor="dgrStatus2">My organisation has unrestricted DGR endorsement with the ATO</label>
        </div>

        <hr />

        <div>
          <input
            type="radio"
            className="radio"
            id="dgrStatus3"
            value="RESTRICTED"
            name="dgrStatus"
            checked={hasRestrictedDgr}
            onChange={(evt: any) => {
              if (evt.target.value) {
                onBankingDataFieldsChange({ dgrStatus: evt.target.value })
              }
            }}
            required
          />
          <label htmlFor="dgrStatus3">My organisation has restricted DGR endorsement with the ATO</label>
        </div>

        {hasRestrictedDgr && (
          <React.Fragment>
            <hr />

            <Label>
              Can you please confirm the funds raised through {displayName} will be spent exclusively on DGR related
              activities?
            </Label>

            <div>
              <input
                type="radio"
                className="radio inline-block mr-md"
                id="subDgrStatus1"
                value="RESTRICTED_DGR_ACTIVITIES_YES"
                name="subDgrStatus"
                checked={bankingData.dgrStatus === 'RESTRICTED_DGR_ACTIVITIES_YES'}
                onChange={(evt: any) => {
                  if (evt.target.value) {
                    onBankingDataFieldsChange({ dgrStatus: evt.target.value })
                  }
                }}
                required
              />
              <label htmlFor="subDgrStatus1">Yes</label>
            </div>

            <div>
              <input
                type="radio"
                className="radio inline-block mr-md"
                id="subDgrStatus2"
                value="RESTRICTED_DGR_ACTIVITIES_NO"
                name="subDgrStatus"
                checked={bankingData.dgrStatus === 'RESTRICTED_DGR_ACTIVITIES_NO'}
                onChange={(evt: any) => {
                  if (evt.target.value) {
                    onBankingDataFieldsChange({ dgrStatus: evt.target.value })
                  }
                }}
                required
              />
              <label htmlFor="subDgrStatus2">No</label>
            </div>
          </React.Fragment>
        )}
      </fieldset>
    </Accordion>
  )
}
