import React, { FunctionComponent } from 'react'
import { THEME } from '../../lib'
import { NavLink } from 'react-router-dom'
import { auth } from '../../firebase'
import { LogoutIcon } from '../shared'

type Props = {
  isAuthenticated: 'yes' | 'no' | 'toBeDecided'
  logout: () => void
}

export const LargeScreenNavBar: FunctionComponent<Props> = ({ isAuthenticated, logout }) => {
  return (
    <div className="hidden lg:flex relative items-center h-6 z-[2]">
      <div className="max-w-screen-2xl mx-auto w-full flex items-center justify-between space-x-lg p-md">
        <div className="flex items-center">
          <img src={`/${THEME.logoUrl}`} className="h-[3.5rem]" alt={`${THEME.displayName} logo`} />
          <span className="text-lg ml-md">Charity</span>
        </div>

        {isAuthenticated === 'yes' && (
          <>
            <div className="flex items-center flex-1">
              <NavLink
                to="/"
                className="inline-block py-sm px-md transition group active:scale-95 hover:underline [&.active]:underline"
              >
                Dashboard
              </NavLink>

              <NavLink
                to="/tos"
                className="inline-block py-sm px-md transition group active:scale-95 hover:underline [&.active]:underline"
              >
                Terms and Conditions
              </NavLink>
            </div>

            <div className="flex items-center space-x-lg">
              <NavLink
                to="/profile"
                className="inline-block py-sm px-md transition group active:scale-95 hover:underline [&.active]:underline"
              >
                {auth.currentUser?.displayName || auth.currentUser?.email}
              </NavLink>

              <button className="flex items-center space-x-md px-md py-sm transition active:scale-95" onClick={logout}>
                <LogoutIcon width="2rem" height="2rem" />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
